import request from '@/utils/request'

// 获取列表
export function getListApi(params: any) {
  return request({
    url: '/notify/get_notice',
    method: 'GET',
    params: params
  })
}

// 详情
export function detailApi(params: any) {
  return request({
    url: '/notify/detail',
    method: 'GET',
    params: params
  })
}
// 删除
export function deleteApi(data: any) {
  return request({
    url: '/ssl/do_del',
    method: 'POST',
    data: data
  })
}
// 全部已读,批量删除
export function allApi(data: any) {
  return request({
    url: '/notify/ajax_do',
    method: 'POST',
    data: data
  })
}

