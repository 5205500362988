/*
  表单校验规则
 */
import i18n from '@/assets/lang/index'
import { Rule } from 'ant-design-vue/lib/form'
const { t } = i18n.global

export const mobileEncry = (mobile: string) => {
  const preg = /(\d{3})\d{4}(\d{4})/
  const mobileData = mobile.replace(preg, '$1****$2')
  return mobileData
}
export const emailEncry = (email: string) => {
  const emailReg = email.match(/(.{1}).+(.{1}@.+)/)
  let emailData = ''
  if (emailReg && emailReg.length > 0) {
    emailData = emailReg[1] + '***' + emailReg[2]
  }
  return emailData
}

export const checkEmail = (email: string) => {
  const RegEmail = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
  return RegEmail.test(email)
}
export const validateEmail = async (_rule: Rule, value: string) => {
  if (value === '') {
    return Promise.reject(t('请输入邮箱'))
  } else if (!checkEmail(value)) {
    return Promise.reject(new Error(t('请输入正确的邮箱！')))
  } else {
    return Promise.resolve()
  }
}

export const checkPassword = (password: string) => {
  let score = 0
  if (/[0-9]/.test(password))score++
  if (/[A-Z]/.test(password))score++
  if (/([a-z])/.test(password))score++
  if (/[_|\-|+|=|*|!|@|#|$|%|^|&|(|)]/.test(password))score++
  // const RegPass = /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*[`~!@#$%^&*()_\-+=<>.?:"{}].*).{8,16}$/
  return score >= 3
}
export const validatePass = async (_rule: Rule, value: string) => {
  if (value === '') {
    return Promise.reject(t('请输入密码'))
  } else if (value.length < 8) {
    return Promise.reject(new Error(t('密码长度至少为8位')))
  } else if (!checkPassword(value)) {
    return Promise.reject(new Error(t('至少包含大写字母、小写字母、数字和特殊字符三种')))
  } else {
    return Promise.resolve()
  }
}

export const checkMobile = (mobile:string) => {
  const RegMobile = /^1[3457869]\d{9}$/
  return RegMobile.test(mobile)
}
export const checkNum = (mobile: string) => {
  const numReg = /^[0-9]+$/
  return numReg.test(mobile)
}

export const ipsComma = (ipvalue: string) => {
  const s_ips = /^(?:(?:^|,)(?:[0-9]|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])(?:\.(?:[0-9]|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])){3})+$/
  return s_ips.test(ipvalue)
}

export const isDomain = (domain: string) => {
  const urlRegex = /^[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/
  return urlRegex.test(domain)
}

export const isUrl = (domain: string) => {
  // const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9]+\.[a-z]{2,}(\/.*)?$/

  const urlRegex = /^http(s)?:\/\/(([0-9]{1,3}\.){3}[0-9]{1,3}|([0-9a-z_!~*\'()-]+\.)*([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\.[a-z]{2,6})(:[0-9]{1,4})?((\/\?)|(\/[0-9a-zA-Z_!~\*\'\(\)\.;\?:@&=\+\$,%#-\/\p{Han}]*)?)$/

  return urlRegex.test(domain)
}

export const isIp = (ip: string) => {
  const ipRegex = /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}|(\d{1,3}\.){3}\d{1,3}\/\d{1,2}$/
  return ipRegex.test(ip)
}

// 支持任意IP地址,支持CIDR格式地址
// export const isIpArr = (ip:any[]) => {
//   const ipArr = ip.filter((item: string) => item !== '')
//   const ipRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4}$|^(?:[A-F0-9]{1,4}:){1,6}:[A-F0-9]{1,4}$/i
//   const cidrRegex = /^(?:[A-F0-9]{1,4}::?){1,7}[A-F0-9]{0,4}\/[0-9]{1,3}$|^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(3[0-2]|[12]?[0-9])$/
//   for (const ipvalue of ipArr) {
//     if (!ipRegex.test(ipvalue.trim()) && !cidrRegex.test(ipvalue.trim())) {
//       return false
//     }
//   }
//   return true
// }
// 支持IPv4
export const isIpArr = (ip:any[]) => {
  const ipArr = ip.filter(item => item !== '')
  const ipRegex = /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}|(\d{1,3}\.){3}\d{1,3}\/\d{1,2}$/
  const newIpArr = ipArr.filter((v) => {
    return !ipRegex.test(v)
  })
  return newIpArr.length === 0
}
export const isUrlArr = (url:any[]) => {
  const urlArr = url.filter((item: string) => item !== '')
  const urlRegex = /^(http|https):\/\/.*/
  const newurlArr = urlArr.filter((v: string) => {
    return !urlRegex.test(v)
  })
  return newurlArr.length === 0
}
