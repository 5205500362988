<template>
  <div class="p-20">
    <div class="mb-24">
      <a-button type="primary" class="mr-24">Primary Button</a-button>
      <a-button  class="mr-24">Button</a-button>
      <a-button type="primary" class="mr-24" disabled>Primary Button</a-button>
    </div>

    <div class="mb-24">
      <a-switch v-model:checked="checked" class="yd-switch" checked-children="ON" un-checked-children="OFF"/>

      <a-switch v-model:checked="checked" class="ml-24" size="small"/>
    </div>

    <div class="mb-24">
      <a-radio-group v-model:value="value1" class="yd-radio">
        <a-radio-button value="a">Hangzhou</a-radio-button>
        <a-radio-button value="b">Shanghai</a-radio-button>
        <a-radio-button value="c">Beijing</a-radio-button>
        <a-radio-button value="d">Chengdu</a-radio-button>
      </a-radio-group>
    </div>

    <div class="mb-24">
      <a-tag class="yd-tag">Tag 1</a-tag>
    </div>

    <div class="mb-24">
      <a-checkbox-group v-model:value="value11" name="checkboxgroup" :options="options" />

      <a-checkbox v-model:checked="checked1" disabled >Shanghai</a-checkbox>
      <a-checkbox v-model:checked="checked2" disabled >Chengdu</a-checkbox>
    </div>

    <a-input v-model:value="value" placeholder="Basic usage" class="mb-24" style="width:400px"/>

    <div class="mb-24">
      <a-textarea v-model:value="value" placeholder="Basic usage" :rows="4"  style="width:400px"/>
    </div>

    <div class="mb-24">
      <a-select v-model:value="value2" style="width: 240px" placeholder="Basic usage" :options="options">
        <template #suffixIcon><CaretDownOutlined  class="ant-select-suffix"/></template>
      </a-select>

      <a-select v-model:value="value2" style="width: 240px;margin-left:24px" disabled placeholder="Basic usage">
        <template #suffixIcon><CaretDownOutlined  class="ant-select-suffix"/></template>
        <a-select-option value="lucy">Lucy</a-select-option>
      </a-select>
    </div>

    <div class="mb-24">
      <a-input-number id="inputNumber" v-model:value="valuenumber" :min="1" />
    </div>
    <div class="mb-24">
      <yd-pagination @change="pageChange" :pageSize="listQuery.page_size" :current="listQuery.page_number"
        :total="500" />
    </div>

    <div class="mb-24 w-620">
      <a-form class="yd-form" ref="formRef" :model="formState" name="basic" :label-col="{ style:{width:'100px'} }" autocomplete="off">
            <a-form-item :label="$t('规则名称')" name="name" :rules="[{ required: true, message: $t('请输入规则名称') }]">
                <a-input :placeholder="$t('请输入规则名称')" v-model:value="formState.name" style="width:218px"/>
            </a-form-item>
            <a-form-item :label="$t('规则名称')" name="name" :rules="[{ required: true, message: $t('请输入规则名称') }]">
              <a-checkbox-group v-model:value="value11" name="checkboxgroup" :options="options" />
            </a-form-item>
            <a-form-item :label="$t('规则名称')" name="name" :rules="[{ required: true, message: $t('请输入规则名称') }]">
              <a-input-number id="inputNumber" v-model:value="valuenumber" :min="1" style="width:90px"/>
            </a-form-item>
            <a-form-item :label="$t('过滤方式')" name="filter_type">
                  <a-select v-model:value="formState.filter_type" :placeholder="$t('请选择')" style="width:218px">
                  <template #suffixIcon><CaretDownOutlined  class="ant-select-suffix"/></template>
                    <a-select-option value="remote">{{$t('远程地址')}}</a-select-option>
                    <a-select-option value="exe">{{$t('应用程序')}}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item :label="$t('远程地址')" name="filter" >
                <a-textarea  v-model:value="formState.filter" placeholder="10.20.100.40:3306" style="width:218px" :auto-size="{ minRows: 2, maxRows: 5 }" />
            </a-form-item>
        </a-form>
    </div>

</div>
</template>
<script setup lang="ts">
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons-vue'
import { reactive, ref } from 'vue'

const checked = ref(false)
const listQuery = reactive({
  page_size: 10,
  page_number: 1
})
const value1 = ref('a')
const value2 = ref(null)
const value11 = ref(['Apple'])
const checked2 = ref(true)
const valuenumber = ref(3)

const options = [
  { label: 'Apple', value: 'Apple' },
  { label: 'Pear', value: 'Pear' },
  { label: 'Orange', value: 'Orange' }
]

interface FormState {
    name:string;
    filter_type:null|string;
    filter:string;
}
const formState = reactive<FormState>({
  filter_type: 'remote',
  name: '',
  filter: ''
})
</script>
