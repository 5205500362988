import request from '@/utils/request'

export function notifyNum (params:any) {
  return request({
    url: '/notify/get_num',
    method: 'GET',
    params: params
  })
}

export function loadNotifies (params:any) {
  return request({
    url: '/notify/load_notifies',
    method: 'GET',
    params: params
  })
}

export function homeData (params:any) {
  return request({
    url: '/home/data',
    method: 'GET',
    params: params
  })
}

export function safeData (params:any) {
  return request({
    url: '/home/safe_data',
    method: 'GET',
    params: params
  })
}

export function bandwidth (params:any) {
  return request({
    url: '/home/bandwidth_top5',
    method: 'GET',
    params: params
  })
}
