import { isIpArr } from '@/utils/Encryption'
import { useRoute } from 'vue-router'

export default {
  baseURL: process.env.NODE_ENV === 'development' ? 'http://yidun-v2-dev.yunyisec.com/yidun-api' : '/yidun-api'
}

export const lineType = {
  0: '全国默认',
  1: '联通',
  2: '电信',
  3: '移动'
}
export const languageTitle = localStorage.getItem('customTitle') ? localStorage.getItem('customTitle') : '弈盾'
export const recordStatus = {
  1: '接入成功',
  2: '正在享受' + languageTitle + '安全加速服务。',
  3: '等待CNAME解析生效',
  4: '域名尚未解析到' + languageTitle + '加速高防智能CNAME解析，无法启用加速保护服务。',
  5: '域名未备案',
  6: '此域名未备案，无法开启加速服务。'
}

export const mywebStatus = {
  1: '选择接入方式',
  2: '待配置子域',
  3: '检测生效',
  4: '审核未通过',
  5: '域名审核中'
}

export const commonPackageList = ['免费版', '专业版', '商务版', '企业版']

export const httpport = [81, 82, 83, 84, 86, 87, 88, 89, 97, 800, 808, 1000, 1090, 3333,
  3501, 3601, 5000, 5222, 6001, 6666, 7000, 7001, 7002, 7003, 7004,
  7005, 7006, 7009, 7010, 7011, 7012, 7013, 7014, 7015, 7016, 7018,
  7019, 7020, 7021, 7022, 7023, 7024, 7025, 7026, 7070, 7081, 7082,
  7083, 7088, 7097, 7510, 7777, 7800, 8000, 8001, 8002, 8003, 8008,
  8009, 8020, 8021, 8022, 8025, 8026, 8077, 8078, 8080, 8081, 8082,
  8083, 8084, 8085, 8086, 8087, 8088, 8089, 8090, 8091, 8106, 8181,
  8334, 8336, 8800, 8686, 8888, 8889, 8999, 9000, 9001, 9002, 9003,
  9021, 9023, 9027, 9037, 9080, 9081, 9082, 9180, 9200, 9201, 9205,
  9207, 9208, 9209, 9210, 9211, 9212, 9213, 9898, 9908, 9916, 9918,
  9919, 9928, 9929, 9939, 9999, 48800, 10000, 10001, 10080, 12601,
  28080, 33702]

export const httpsport = [
  4443, 5443, 6443, 7443, 8443, 9443, 8553, 8663, 9553, 9663, 18980, 30000, 30006
]

export const customObj = [
  { label: '默认', value: 'default' },
  { label: '自定义', value: 'diy' }
]

export const attackType = {
  sql: 'SQL注入攻击',
  xss: '跨站脚本攻击',
  webshell: 'Webshell探测',
  command: '命令执行',
  code: '代码执行',
  dir_list: '敏感文件访问',
  fi: '本地/远程文件包含',
  scan: '恶意扫描',
  special: '特殊攻击',
  bot: '恶意采集',
  other: '其他攻击',
  file_upload: '文件上传',
  CC: 'CC攻击',
  cc: 'CC攻击',
  acl: '访问控制'
}

export const attackTrendArr = [
  'SQL注入攻击',
  '跨站脚本攻击',
  'Webshell探测',
  '命令执行',
  '代码执行',
  '敏感文件访问',
  '本地/远程文件包含',
  '恶意扫描',
  '特殊攻击',
  '恶意采集',
  '文件上传',
  'CC攻击',
  '访问控制',
  '其他攻击'
]

// export const attackType = {
//   normal: '正常请求',
//   sql: 'SQL注入攻击',
//   xss: '跨站脚本攻击',
//   rce: '代码执行漏洞',
//   cmd: '命令注入',
//   scan: '扫描探测',
//   dir: '目录遍历',
//   blackip: 'IP黑名单',
//   other: '其他类型攻击',
//   blackua: 'UA黑名单',
//   blackurls: 'URL黑名单',
//   blackurl: 'URL黑名单',
//   CC: 'CC攻击'
// }
export const oftenTimeList = [
  { label: '今天', value: 'today' },
  { label: '昨天', value: 'yesterday' }
  // { label: '本周', value: 'curr_week' },
  // { label: '上周', value: 'last_week' },
  // { label: '本月', value: 'curr_month' },
  // { label: '上月', value: 'last_month' }
]

export const latelyTimeList = [
  { label: '最近10分钟', value: '10/m' },
  { label: '最近30分钟', value: '30/m' },
  { label: '最近1小时', value: '1/h' },
  { label: '最近1天', value: '1/d' }
  // { label: '最近2天', value: '2/d' },
  // { label: '最近7天', value: '7/d' }
]

export const fastTimeList = [
  { label: '今天', value: 'today' },
  { label: '昨天', value: 'yesterday' },
  { label: '最近10分钟', value: '10/m', num: 600 },
  { label: '最近30分钟', value: '30/m', num: 1800 },
  { label: '最近1小时', value: '1/h', num: 3600 }
]

export const worldNameMap = {
  Afghanistan: '阿富汗',
  Angola: '安哥拉',
  Albania: '阿尔巴尼亚',
  Algeria: '阿尔及利亚',
  Argentina: '阿根廷',
  Armenia: '亚美尼亚',
  Australia: '澳大利亚',
  Austria: '奥地利',
  Azerbaijan: '阿塞拜疆',
  Bahamas: '巴哈马',
  Bangladesh: '孟加拉国',
  Belgium: '比利时',
  Benin: '贝宁',
  'Burkina Faso': '布基纳法索',
  Burundi: '布隆迪',
  Bulgaria: '保加利亚',
  'Bosnia and Herz.': '波斯尼亚和黑塞哥维那',
  Belarus: '白俄罗斯',
  Belize: '伯利兹',
  Bermuda: '百慕大群岛',
  Bolivia: '玻利维亚',
  Brazil: '巴西',
  Brunei: '文莱',
  Bhutan: '不丹',
  Botswana: '博茨瓦纳',
  Cambodia: '柬埔寨',
  Cameroon: '喀麦隆',
  Canada: '加拿大',
  'Central African Rep.': '中非共和国',
  Chad: '乍得',
  Chile: '智利',
  China: '中国',
  Colombia: '哥伦比亚',
  Congo: '刚果',
  'Costa Rica': '哥斯达黎加',
  "Côte d'Ivoire": '科特迪瓦',
  Croatia: '克罗地亚',
  Cuba: '古巴',
  Cyprus: '塞浦路斯',
  'Czech Rep.': '捷克共和国',
  'Dem. Rep. Korea': '韩国',
  'Dem. Rep. Congo': '民主刚果',
  Denmark: '丹麦',
  Djibouti: '吉布提',
  'Dominican Rep.': '多米尼加共和国',
  Ecuador: '厄瓜多尔',
  Egypt: '埃及',
  'El Salvador': '萨尔瓦多',
  'Eq. Guinea': '赤道几内亚',
  Eritrea: '厄立特里亚',
  Estonia: '爱沙尼亚',
  Ethiopia: '埃塞俄比亚',
  'Falkland Is.': '福克兰群岛',
  Fiji: '斐济',
  Finland: '芬兰',
  France: '法国',
  'French Guiana': '法属圭亚那',
  'Fr. S. Antarctic Lands': '法属南部领地',
  Gabon: '加蓬',
  Gambia: '冈比亚',
  Germany: '德国',
  Georgia: '佐治亚州',
  Ghana: '加纳',
  Greece: '希腊',
  Greenland: '格陵兰',
  Guatemala: '危地马拉',
  Guinea: '几内亚',
  'Guinea-Bissau': '几内亚比绍',
  Guyana: '圭亚那',
  Haiti: '海地',
  'Heard I. and McDonald Is.': '赫德岛和麦克唐纳群岛',
  Honduras: '洪都拉斯',
  Hungary: '匈牙利',
  Iceland: '冰岛',
  India: '印度',
  Indonesia: '印度尼西亚',
  Iran: '伊朗',
  Iraq: '伊拉克',
  Ireland: '爱尔兰',
  Israel: '以色列',
  Italy: '意大利',
  'Ivory Coast': '象牙海岸',
  Jamaica: '牙买加',
  Japan: '日本',
  Jordan: '乔丹',
  Kashmir: '克什米尔',
  Kazakhstan: '哈萨克斯坦',
  Kenya: '肯尼亚',
  Kosovo: '科索沃',
  Kuwait: '科威特',
  Kyrgyzstan: '吉尔吉斯斯坦',
  Laos: '老挝',
  'Lao PDR': '老挝人民民主共和国',
  Latvia: '拉脱维亚',
  Lebanon: '黎巴嫩',
  Lesotho: '莱索托',
  Liberia: '利比里亚',
  Libya: '利比亚',
  Lithuania: '立陶宛',
  Luxembourg: '卢森堡',
  Madagascar: '马达加斯加',
  Macedonia: '马其顿',
  Malawi: '马拉维',
  Malaysia: '马来西亚',
  Mali: '马里',
  Mauritania: '毛里塔尼亚',
  Mexico: '墨西哥',
  Moldova: '摩尔多瓦',
  Mongolia: '蒙古',
  Montenegro: '黑山',
  Morocco: '摩洛哥',
  Mozambique: '莫桑比克',
  Myanmar: '缅甸',
  Namibia: '纳米比亚',
  Netherlands: '荷兰',
  'New Caledonia': '新喀里多尼亚',
  'New Zealand': '新西兰',
  Nepal: '尼泊尔',
  Nicaragua: '尼加拉瓜',
  Niger: '尼日尔',
  Nigeria: '尼日利亚',
  Korea: '朝鲜',
  'Northern Cyprus': '北塞浦路斯',
  Norway: '挪威',
  Oman: '阿曼',
  Pakistan: '巴基斯坦',
  Panama: '巴拿马',
  'Papua New Guinea': '巴布亚新几内亚',
  Paraguay: '巴拉圭',
  Peru: '秘鲁',
  'Republic of the Congo': '刚果共和国',
  Philippines: '菲律宾',
  Poland: '波兰',
  Portugal: '葡萄牙',
  'Puerto Rico': '波多黎各',
  Qatar: '卡塔尔',
  'Republic of Serbia': '塞尔维亚共和国',
  Romania: '罗马尼亚',
  Russia: '俄罗斯',
  Rwanda: '卢旺达',
  Samoa: '萨摩亚',
  'Saudi Arabia': '沙特阿拉伯',
  Senegal: '塞内加尔',
  Serbia: '塞尔维亚',
  'Sierra Leone': '塞拉利昂',
  Slovakia: '斯洛伐克',
  Slovenia: '斯洛文尼亚',
  'Solomon Is.': '所罗门群岛',
  Somaliland: '索马里兰',
  Somalia: '索马里',
  'South Africa': '南非',
  'S. Geo. and S. Sandw. Is.': '南乔治亚和南桑德威奇群岛',
  'S. Sudan': '南苏丹',
  Spain: '西班牙',
  'Sri Lanka': '斯里兰卡',
  Sudan: '苏丹',
  Suriname: '苏里南',
  Swaziland: '斯威士兰',
  Sweden: '瑞典',
  Switzerland: '瑞士',
  Syria: '叙利亚',
  Tajikistan: '塔吉克斯坦',
  Tanzania: '坦桑尼亚',
  Thailand: '泰国',
  'Timor-Leste': '东帝汶',
  Togo: '多哥',
  'Trinidad and Tobago': '特立尼达和多巴哥',
  Tunisia: '突尼斯',
  Turkey: '土耳其',
  Turkmenistan: '土库曼斯坦',
  Uganda: '乌干达',
  Ukraine: '乌克兰',
  'United Arab Emirates': '阿拉伯联合酋长国',
  'United Kingdom': '大不列颠联合王国',
  'United Republic of Tanzania': '坦桑尼亚联合共和国',
  'United States': '美国',
  'United States of America': '美利坚合众国',
  Uruguay: '乌拉圭',
  Uzbekistan: '乌兹别克斯坦',
  Vanuatu: '瓦努阿图',
  Venezuela: '委内瑞拉',
  Vietnam: '越南',
  'West Bank': '西岸',
  'W. Sahara': '西撒哈拉',
  Yemen: '也门',
  Zambia: '赞比亚',
  Zimbabwe: '津巴布韦'
}

export const attackTableJson = {
  msec: '攻击时间',
  clientip: '黑客IP',
  method: '攻击方式',
  domain: '攻击域名',
  request: '攻击地址',
  referer: '来源地址',
  statusText: '处理状态',
  size: '字节长度',
  agent: '客户端信息',
  ruleid: '攻击类型',
  prov: '地域归属'
}

export const timeJson = {
  '/s': '秒前',
  '/m': '分钟前',
  '/h': '小时前',
  '/d': '天前'
}
export const conditionOptions = [
  { label: 'URI', value: 'url', tooltip: '请求的URL，不带域名，如请求 ' + window.location.origin + '/index.html?a=1&b=2，这里的 URI 为 /index.html?a=1&b=2' },
  { label: 'URI Path', value: 'urlPath', tooltip: '请求的URI路径，不带域名，如请求 ' + window.location.origin + '/index.html?a=1&b=2，这里的 URI Path 为 /index.html' },
  { label: 'IP', value: 'ip', tooltip: '请求的来源IP' },
  { label: 'User-Agent', value: 'userAgent', tooltip: '发起请求的客户端的浏览器标识、渲染引擎标识和版本信息等浏览器相关信息' },
  { label: 'Referer', value: 'referer', tooltip: '发起请求的客户端的浏览器标识、渲染引擎标识和版本信息等浏览器相关信息' },
  { label: 'Header', value: 'header', tooltip: '请求的头部信息' },
  { label: 'Query String', value: 'queryString', tooltip: '请求中的查询字符串，具体指URL中问号（?）后面的部分' },
  { label: 'Query String Parameter', value: 'queryStringParam', tooltip: '请求参数的参数名。如请求 ' + window.location.origin + '/request_path?param1=a&param2=b中，param1和param1都是请求参数名' },
  { label: 'Cookie', value: 'cookie', tooltip: '请求中的Cookie信息' },
  { label: 'Cookie Name', value: 'cookieName', tooltip: 'Cookie的键名称。如在yidun_tc:111这个Cookie中，yidun_tc是Cookie的键名称' },
  { label: 'File Extension', value: 'fileExtension', tooltip: '被请求文件的扩展名，如.png、.php' }
]
export const matchFieObj = {
  url: 'URI',
  ip: 'IP',
  urlPath: 'URI Path',
  queryString: 'Query String',
  userAgent: 'User Agent',
  referer: 'Referer',
  header: 'Header',
  queryStringParam: 'Query String Parameter',
  cookie: 'Cookie',
  cookieName: 'Cookie Name',
  fileExtension: 'File Extension'
}
export const operatorObj = {
  exists: '存在',
  'not-exists': '不存在',
  empty: '内容为空',
  equal: '等于',
  'not-equal': '不等于',
  'match-one': '等于多值之一',
  'all-not-match': '不等于任一值',
  contain: '包含',
  'not-contain': '不包含',
  'contain-one': '包含多值之一',
  'all-not-contain': '不包含任一值',
  'regexp-match': '正则匹配',
  'not-regexp-match': '正则不匹配',
  'prefix-match': '前缀匹配',
  'suffix-match': '后缀匹配',
  'len-lt': '长度小于',
  'len-eq': '长度等于',
  'len-gt': '长度大于',
  belong: '属于',
  'not-belong': '不属于'
}

export const methodJson = {
  url: {
    params: '',
    symbolOptions: [
      { label: '不包含', value: 'not-contain' },
      { label: '包含', value: 'contain' },
      { label: '不等于', value: 'not-equal' },
      { label: '等于', value: 'equal' },
      { label: '长度小于', value: 'len-lt' },
      { label: '长度等于', value: 'len-eq' },
      { label: '长度大于', value: 'len-gt' },
      { label: '等于多值之一', value: 'match-one' },
      { label: '不等于任一值', value: 'all-not-match' },
      { label: '包含多值之一', value: 'contain-one' },
      { label: '不包含任一值', value: 'all-not-contain' },
      { label: '前缀匹配', value: 'prefix-match' },
      { label: '后缀匹配', value: 'suffix-match' }
    ],
    conPlace: '如/login.php?a=1，不包含域名'
  },
  urlPath: {
    params: '',
    symbolOptions: [
      { label: '不包含', value: 'not-contain' },
      { label: '包含', value: 'contain' },
      { label: '不等于', value: 'not-equal' },
      { label: '等于', value: 'equal' },
      { label: '长度小于', value: 'len-lt' },
      { label: '长度等于', value: 'len-eq' },
      { label: '长度大于', value: 'len-gt' },
      { label: '等于多值之一', value: 'match-one' },
      { label: '不等于任一值', value: 'all-not-match' },
      { label: '包含多值之一', value: 'contain-one' },
      { label: '不包含任一值', value: 'all-not-contain' },
      // { label: '正则不匹配', value: 'not-regexp-match' },
      // { label: '正则匹配', value: 'regexp_match' },
      { label: '前缀匹配', value: 'prefix-match' },
      { label: '后缀匹配', value: 'suffix-match' }
    ],
    conPlace: '只允许填写一个匹配项'
  },
  ip: {
    params: '',
    symbolOptions: [
      { label: '不属于', value: 'not-belong' },
      { label: '属于', value: 'belong' }
    ],
    conPlace: '请填入IP或IP/掩码（例如：1.1.1.1/24）。暂不支持正则，最多填写100个，以英文逗号分隔，或按回车键确认。'
  },
  userAgent: {
    params: '',
    symbolOptions: [
      { label: '不包含', value: 'not-contain' },
      { label: '包含', value: 'contain' },
      { label: '不等于', value: 'not-equal' },
      { label: '等于', value: 'equal' },
      { label: '长度小于', value: 'len-lt' },
      { label: '长度等于', value: 'len-eq' },
      { label: '长度大于', value: 'len-gt' },
      { label: '等于多值之一', value: 'match-one' },
      { label: '不等于任一值', value: 'all-not-match' },
      { label: '包含多值之一', value: 'contain-one' },
      { label: '不包含任一值', value: 'all-not-contain' },
      // { label: '正则不匹配', value: 'not-regexp-match' },
      // { label: '正则匹配', value: 'regexp_match' },
      { label: '前缀匹配', value: 'prefix-match' },
      { label: '后缀匹配', value: 'suffix-match' },
      { label: '存在', value: 'exists' },
      { label: '不存在', value: 'not-exists' },
      { label: '内容为空', value: 'empty' }
    ],
    conPlace: '只允许填写一个匹配项'
  },
  referer: {
    params: '',
    symbolOptions: [
      { label: '不包含', value: 'not-contain' },
      { label: '包含', value: 'contain' },
      { label: '不等于', value: 'not-equal' },
      { label: '等于', value: 'equal' },
      { label: '长度小于', value: 'len-lt' },
      { label: '长度等于', value: 'len-eq' },
      { label: '长度大于', value: 'len-gt' },
      { label: '等于多值之一', value: 'match-one' },
      { label: '不等于任一值', value: 'all-not-match' },
      { label: '包含多值之一', value: 'contain-one' },
      { label: '不包含任一值', value: 'all-not-contain' },
      // { label: '正则不匹配', value: 'not-regexp-match' },
      // { label: '正则匹配', value: 'regexp_match' },
      { label: '前缀匹配', value: 'prefix-match' },
      { label: '后缀匹配', value: 'suffix-match' },
      { label: '存在', value: 'exists' },
      { label: '不存在', value: 'not-exists' },
      { label: '内容为空', value: 'empty' }
    ],
    conPlace: '请求的来源网址，即该请求从哪个页面跳转产生'
  },
  header: {
    params: '',
    custom: '自定义Header',
    symbolOptions: [
      { label: '不包含', value: 'not-contain' },
      { label: '包含', value: 'contain' },
      { label: '不等于', value: 'not-equal' },
      { label: '等于', value: 'equal' },
      { label: '长度小于', value: 'len-lt' },
      { label: '长度等于', value: 'len-eq' },
      { label: '长度大于', value: 'len-gt' },
      { label: '等于多值之一', value: 'match-one' },
      { label: '不等于任一值', value: 'all-not-match' },
      { label: '包含多值之一', value: 'contain-one' },
      { label: '不包含任一值', value: 'all-not-contain' },
      // { label: '正则不匹配', value: 'not-regexp-match' },
      // { label: '正则匹配', value: 'regexp_match' },
      { label: '前缀匹配', value: 'prefix-match' },
      { label: '后缀匹配', value: 'suffix-match' },
      { label: '不存在', value: 'not-exists' },
      { label: '存在', value: 'exists' }
    ],
    conPlace: '只允许填写一个匹配项'
  },
  queryString: {
    params: '',
    symbolOptions: [
      { label: '不包含', value: 'not-contain' },
      { label: '包含', value: 'contain' },
      { label: '不等于', value: 'not-equal' },
      { label: '等于', value: 'equal' },
      { label: '长度小于', value: 'len-lt' },
      { label: '长度等于', value: 'len-eq' },
      { label: '长度大于', value: 'len-gt' },
      { label: '等于多值之一', value: 'match-one' },
      { label: '不等于任一值', value: 'all-not-match' },
      { label: '包含多值之一', value: 'contain-one' },
      { label: '不包含任一值', value: 'all-not-contain' },
      // { label: '正则不匹配', value: 'not-regexp-match' },
      // { label: '正则匹配', value: 'regexp_match' },
      { label: '前缀匹配', value: 'prefix-match' },
      { label: '后缀匹配', value: 'suffix-match' },
      { label: '存在', value: 'exists' },
      { label: '不存在', value: 'not-exists' }
    ],
    conPlace: '只允许填写一个匹配项'
  },
  queryStringParam: {
    params: '',
    custom: '自定义Paramter',
    symbolOptions: [
      { label: '不包含', value: 'not-contain' },
      { label: '包含', value: 'contain' },
      { label: '不等于', value: 'not-equal' },
      { label: '等于', value: 'equal' },
      { label: '长度小于', value: 'len-lt' },
      { label: '长度等于', value: 'len-eq' },
      { label: '长度大于', value: 'len-gt' },
      { label: '等于多值之一', value: 'match-one' },
      { label: '不等于任一值', value: 'all-not-match' },
      { label: '包含多值之一', value: 'contain-one' },
      { label: '不包含任一值', value: 'all-not-contain' },
      // { label: '正则不匹配', value: 'not-regexp-match' },
      // { label: '正则匹配', value: 'regexp_match' },
      { label: '前缀匹配', value: 'prefix-match' },
      { label: '后缀匹配', value: 'suffix-match' },
      { label: '存在', value: 'exists' },
      { label: '不存在', value: 'not-exists' },
      { label: '内容为空', value: 'empty' }
    ],
    conPlace: '只允许填写一个匹配项'
  },
  cookie: {
    params: '',
    symbolOptions: [
      { label: '不包含', value: 'not-contain' },
      { label: '包含', value: 'contain' },
      { label: '不等于', value: 'not-equal' },
      { label: '等于', value: 'equal' },
      { label: '长度小于', value: 'len-lt' },
      { label: '长度等于', value: 'len-eq' },
      { label: '长度大于', value: 'len-gt' },
      { label: '等于多值之一', value: 'match-one' },
      { label: '不等于任一值', value: 'all-not-match' },
      { label: '包含多值之一', value: 'contain-one' },
      { label: '不包含任一值', value: 'all-not-contain' },
      // { label: '正则不匹配', value: 'not-regexp-match' },
      // { label: '正则匹配', value: 'regexp_match' },
      { label: '存在', value: 'exists' },
      { label: '不存在', value: 'not-exists' }
    ],
    conPlace: '只允许填写一个匹配项'
  },
  cookieName: {
    params: '',
    custom: '自定义Cookie-Exact',
    symbolOptions: [
      { label: '不包含', value: 'not-contain' },
      { label: '包含', value: 'contain' },
      { label: '不等于', value: 'not-equal' },
      { label: '等于', value: 'equal' },
      { label: '长度小于', value: 'len-lt' },
      { label: '长度等于', value: 'len-eq' },
      { label: '长度大于', value: 'len-gt' },
      { label: '等于多值之一', value: 'match-one' },
      { label: '不等于任一值', value: 'all-not-match' },
      { label: '包含多值之一', value: 'contain-one' },
      { label: '不包含任一值', value: 'all-not-contain' },
      // { label: '正则不匹配', value: 'not-regexp-match' },
      // { label: '正则匹配', value: 'regexp_match' },
      { label: '前缀匹配', value: 'prefix-match' },
      { label: '后缀匹配', value: 'suffix-match' },
      { label: '存在', value: 'exists' },
      { label: '不存在', value: 'not-exists' },
      { label: '内容为空', value: 'empty' }
    ],
    conPlace: '只允许填写一个匹配项'
  },
  fileExtension: {
    params: '',
    symbolOptions: [
      { label: '不包含', value: 'not-contain' },
      { label: '包含', value: 'contain' },
      { label: '不等于', value: 'not-equal' },
      { label: '等于', value: 'equal' },
      { label: '长度小于', value: 'len-lt' },
      { label: '长度等于', value: 'len-eq' },
      { label: '长度大于', value: 'len-gt' },
      { label: '等于多值之一', value: 'match-one' },
      { label: '不等于任一值', value: 'all-not-match' },
      { label: '包含多值之一', value: 'contain-one' },
      { label: '不包含任一值', value: 'all-not-contain' },
      // { label: '正则不匹配', value: 'not-regexp-match' },
      // { label: '正则匹配', value: 'regexp_match' },
      { label: '前缀匹配', value: 'prefix-match' },
      { label: '后缀匹配', value: 'suffix-match' },
      { label: '存在', value: 'exists' },
      { label: '不存在', value: 'not-exists' },
      { label: '内容为空', value: 'empty' }
    ],
    conPlace: '只允许填写一个匹配项'
  },
  whole_site: {
    params: '',
    symbolOptions: [],
    conPlace: 'disabled'
  }
}

export const ccMethod = [
  { label: 'URI', value: 'url', tooltip: '请求的URL，不带域名，如请求 ' + window.location.origin + '/index.html?a=1&b=2，这里的 URI 为 /index.html?a=1&b=2' },
  { label: 'Header', value: 'header', tooltip: '请求的头部信息' }
  // { label: '全站', value: 'whole_site', tooltip: '请求的头部信息' }
  // { label: 'Method', value: 'method' },
  // { label: 'Query', value: 'Query' },
  // { label: 'Referer', value: 'Referer' },
  // { label: 'Cookie', value: 'Cookie' },
  // { label: 'User-Agent', value: 'userAgent' },
  // { label: '自定义请求头', value: 'custom' }
]

export function getSymbolPlace (symbol: string, field?:string, type?:string) {
  let placeValue = ''
  switch (symbol) {
    case 'contain':
    case 'not-contain':
    case 'equal':
    case 'not-equal':
      placeValue = '只允许填写一个匹配项'
      break
    case 'len-lt':
    case 'len-eq':
    case 'len-gt':
      placeValue = '请输入0-8192的整数'
      break
    case 'match-one':
    case 'all-not-match':
    case 'contain-one':
    case 'all-not-contain':
      placeValue = '可以输入多个，最多50个，按回车键确认。'
      break
    case 'exists':
    case 'not-exists':
    case 'empty':
      placeValue = 'disabled'
      break
    default:
      placeValue = '只允许填写一个匹配项'
  }
  if (field === 'whole_site') {
    placeValue = 'disabled'
  }
  return placeValue
}

export function validSymbol (symbol: string, value:any) {
  let symbolReg = ''
  switch (symbol) {
    case 'len-lt':
    case 'len-eq':
    case 'len-gt':
      symbolReg = typeof value === 'number' ? '' : '请输入整数'
      break
    case 'match-one':
    case 'all-not-match':
    case 'contain-one':
    case 'all-not-contain':
      symbolReg = value.length === 0 ? '请输入' : value.length > 50 ? '最多输入50个' : ''
      break
    case 'belong':
    case 'not-belong':
      symbolReg = value.length === 0 ? '请输入' : value.length > 100 ? '最多输入100个' : isIpArr(value) ? '' : 'IP格式错误'
      break
    default:
      symbolReg = ''
  }
  return symbolReg
}

export const ccMethodJson = {
  url: {
    params: '',
    symbolOptions: [
      { label: '不包含', value: 'not-contain' },
      { label: '包含', value: 'contain' },
      { label: '不等于', value: 'not-equal' },
      { label: '等于', value: 'equal' },
      { label: '长度小于', value: 'len-lt' },
      { label: '长度等于', value: 'len-eq' },
      { label: '长度大于', value: 'len-gt' },
      { label: '等于多值之一', value: 'match-one' },
      { label: '不等于任一值', value: 'all-not-match' },
      { label: '包含多值之一', value: 'contain-one' },
      { label: '不包含任一值', value: 'all-not-contain' },
      // { label: '正则不匹配', value: 'not-regexp-match' },
      // { label: '正则匹配', value: 'regexp_match' },
      { label: '前缀匹配', value: 'prefix-match' },
      { label: '后缀匹配', value: 'suffix-match' }
    ],
    conPlace: '如/login.php?a=1，不包含域名'
  },
  method: {
    params: '',
    symbolOptions: [
      { label: '等于', value: 'equal' },
      { label: '不等于', value: 'false' }
    ],
    conPlace: '填写Value值，512个字符内'
  },
  Query: {
    params: '填写Key值',
    symbolOptions: [
      { label: '等于', value: 'equal' },
      { label: '不等于', value: 'false' }
    ],
    conPlace: '填写Value值，512个字符内'
  },
  Referer: {
    params: '',
    symbolOptions: [
      { label: '等于', value: 'equal' },
      { label: '不等于', value: 'not-equal' },
      { label: '前缀匹配', value: 'true' },
      { label: '包含', value: 'contain' },
      { label: '不包含', value: 'not-contain' },
      { label: '后缀匹配', value: 'suffix-match' }
    ],
    conPlace: '填写Value值，512个字符内'
  },
  Cookie: {
    params: '填写Key值',
    symbolOptions: [
      { label: '等于', value: 'equal' },
      { label: '不等于', value: 'not-equal' },
      { label: '包含', value: 'contain' },
      { label: '不包含', value: 'false' }
    ],
    conPlace: '填写Value值，512个字符内'
  },
  userAgent: {
    params: '',
    symbolOptions: [
      { label: '等于', value: 'equal' },
      { label: '不等于', value: 'not-equal' },
      { label: '包含', value: 'contain' },
      { label: '不包含', value: 'false' }
    ],
    conPlace: '填写Value值，512个字符内'
  },
  custom: {
    params: '填写Key值',
    symbolOptions: [
      { label: '等于', value: 'equal' },
      { label: '不等于', value: 'not-equal' },
      { label: '包含', value: 'contain' },
      { label: '不包含', value: 'not-contain' },
      { label: '内容为空', value: 'true' },
      { label: '不存在', value: 'false' }
    ],
    conPlace: '填写Value值，512个字符内'
  },
  whole_site: {
    params: '',
    symbolOptions: [],
    conPlace: 'disabled'
  }
}

export const interOptions = [
  {
    label: '亚洲',
    value: 'asia',
    children: [
      // { label: '中国', value: 'china' },
      { label: '印度', value: 'india' },
      { label: '日本', value: 'japan' },
      { label: '韩国', value: 'southKorea' },
      { label: '新加坡', value: 'Singapore' },
      { label: '沙特阿拉伯', value: 'Saudi Arabia' },
      { label: '阿联酋', value: 'United Arab Emirates' },
      { label: '以色列', value: 'Israel' },
      { label: '土耳其', value: 'Turkey' },
      { label: '俄罗斯', value: 'Russia' }
    ]
  },
  {
    label: '欧洲',
    value: 'europe',
    children: [
      { label: '英国', value: 'uk' },
      { label: '法国', value: 'france' },
      { label: '德国', value: 'germany' },
      { label: '意大利', value: 'italy' },
      { label: '西班牙', value: 'Spain' },
      { label: '葡萄牙', value: 'Portugal' },
      { label: '瑞士', value: 'Switzerland' },
      { label: '瑞典', value: 'Sweden' },
      { label: '挪威', value: 'Norway' },
      { label: '芬兰', value: 'Finland' },
      { label: '荷兰', value: 'Netherlands' },
      { label: '比利时', value: 'Belgium' }
    ]
  },
  {
    label: '非洲',
    value: 'africa',
    children: [
      { label: '埃及', value: 'egypt' },
      { label: '南非', value: 'southAfrica' },
      { label: '尼日利亚', value: 'nigeria' },
      { label: '肯尼亚', value: 'Kenya' },
      { label: '摩洛哥', value: 'Morocco' },
      { label: '加纳', value: 'Ghana' },
      { label: '坦桑尼亚', value: 'Tanzania' },
      { label: '阿尔及利亚', value: 'Algeria' },
      { label: '埃塞俄比亚', value: 'Ethiopia' },
      { label: '乌干达', value: 'Uganda' },
      { label: '赞比亚', value: 'Zambia' },
      { label: '津巴布韦', value: 'Zimbabwe' }
    ]
  },
  {
    label: '北美洲',
    value: 'north',
    children: [
      { label: '美国', value: 'United States' },
      { label: '加拿大', value: 'Canada' },
      { label: '墨西哥', value: 'Mexico' },
      { label: '牙买加', value: 'Jamaica' },
      { label: '古巴', value: 'Cuba' },
      { label: '巴哈马', value: 'Bahamas' },
      { label: '危地马拉', value: 'Guatemala' },
      { label: '洪都拉斯', value: 'Honduras' },
      { label: '尼加拉瓜', value: 'Nicaragua' },
      { label: '哥斯达黎加', value: 'Costa Rica' },
      { label: '巴拿马', value: 'Panama' },
      { label: '特立尼达和多巴哥', value: 'Trinidad and Tobago' }
    ]
  },
  {
    label: '南美洲',
    value: 'south',
    children: [
      { label: '巴西', value: 'Brazil' },
      { label: '阿根廷', value: 'Argentina' },
      { label: '秘鲁', value: 'Peru' },
      { label: '哥伦比亚', value: 'Colombia' },
      { label: '委内瑞拉', value: 'Venezuela' },
      { label: '智利', value: 'Chile' },
      { label: '厄瓜多尔', value: 'Ecuador' },
      { label: '玻利维亚', value: 'Bolivia' },
      { label: '乌拉圭', value: 'Uruguay' },
      { label: '巴拉圭', value: 'Paraguay' },
      { label: '圭亚那', value: 'Guyana' },
      { label: '苏里南', value: 'Suriname' }
    ]
  },
  {
    label: '大洋洲',
    children: [
      { label: '澳大利亚', value: 'Australia' },
      { label: '新西兰', value: 'New Zealand' },
      { label: '巴布亚新几内亚', value: 'Papua New Guinea' },
      { label: '斐济', value: 'Fiji' },
      { label: '所罗门群岛', value: 'Solomon Islands' },
      { label: '瓦努阿图', value: 'Vanuatu' },
      { label: '萨摩亚', value: 'Samoa' },
      { label: '基里巴斯', value: 'Kiribati' },
      { label: '图瓦卢', value: 'Tuvalu' },
      { label: '密克罗尼西亚', value: 'Micronesia' },
      { label: '马绍尔群岛', value: 'Marshall Islands' },
      { label: '帕劳', value: 'Palau' }
    ]
  }

]

export const attackTags = {
  SQL注入攻击: '#DB0F0F',
  跨站脚本攻击: '#DB0F74',
  Webshell探测: '#A90FDB',
  命令执行: '#7C34E9',
  代码执行: '#6E51E9',
  敏感文件访问: '#0D71D7',
  '本地/远程文件': '#0FC1DB',
  恶意扫描: '#20B872',
  特殊攻击: '#98D614',
  恶意采集: '#84A21D',
  访问控制: '#E3B424',
  文件上传: '#D07702',
  CC攻击: '#D34F1B',
  其他攻击: '#D31B1B'

}

export const actionType = {
  1: '阻断',
  0: '人机识别',
  4: '观察',
  5: '重定向',
  6: '放行',
  2: 'JS 验证',
  3: 'Cookie 验证',
  7: '验证码'

}

export const actionDataArr = [
  { label: '全部', value: '' },
  { label: '阻断', value: '1' },
  { label: '观察', value: '4' },
  { label: '重定向', value: '5' },
  { label: '放行', value: '6' },
  { label: 'JS 验证', value: '2' },
  { label: 'Cookie 验证', value: '3' }
  // { label: '验证码', value: '7' }
]

export const ccActionDataArr = [
  { label: '全部', value: '' },
  { label: '阻断', value: '1' },
  { label: 'JS 验证', value: '2' },
  { label: 'Cookie 验证', value: '3' }
  // { label: '验证码', value: '7' }
]
