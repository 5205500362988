export const formatMenus = (menus = []): Array<any> => {
  return menus.map((item: any) => {
    return {
      ...item,
      path: item.link,
      meta: {
        title: item.name,
        icon: item.icon
      },
      children: item.children?.length > 0 ? formatMenus(item.children) : []
    }
  })
}
