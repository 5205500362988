<template>
  <a-pagination class="yd-page" :show-total="showTotal" size="small" show-size-changer :showQuickJumper="true" :page-size-options="pageSizeOptions"/>
</template>

<script setup lang='ts'>
const showTotal = (total: number) => `共 ${total} 条`
const pageSizeOptions = ['10', '15', '20', '50', '100']
</script>

<style>

</style>
