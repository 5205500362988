import router from '@/router/index'
import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

export const useUserStore = defineStore('user', () => {
  const loadingInstance = ref(null)

  const userInfo = ref({
  })
  const token = ref(window.localStorage.getItem('token') || '')
  const setToken = (val:string) => {
    token.value = val
  }

  const csrfToken = ref(window.localStorage.getItem('csrfToken') || '')
  const setcsrfToken = (val:string) => {
    csrfToken.value = val
  }

  const ansyRouter = ref([])
  const setRouter = (val: never[]) => {
    ansyRouter.value = val
  }
  const NeedInit = () => {
    token.value = ''
    window.localStorage.removeItem('token')
    localStorage.clear()
  }

  const ResetUserInfo = (value = {}) => {
    userInfo.value = {
      ...userInfo.value,
      ...value
    }
  }
  /* 登录 */
  const LoginIn = (data: any) => {
    localStorage.setItem('username', data.name)
    localStorage.setItem('isAdmin', data.isAdmin)
    setToken(data['x-access-token'])
    // router.push({ path: '/overview/index', name: 'overviewIndex' })
  }
  /* 登出 */
  const LoginOut = async () => {
    token.value = ''
    sessionStorage.clear()
    localStorage.clear()
    router.push({ path: '/login', name: 'login', replace: true })
    // window.location.reload()
  }

  /* 登出 */
  const HomeLoginOut = async () => {
    token.value = ''
    sessionStorage.clear()
    localStorage.clear()
    location.reload()
  }
  /* 清理数据 */
  const ClearStorage = async () => {
    token.value = ''
    sessionStorage.clear()
    localStorage.clear()
  }

  /* 获取路由 */
  const getMenuList = (data: any) => {
    console.log(data)
    ansyRouter.value = data
    // setRouter(data)
  }
  watch(() => token.value, () => {
    window.localStorage.setItem('token', token.value)
  })

  return {
    userInfo,
    token,
    csrfToken,
    NeedInit,
    ResetUserInfo,
    LoginIn,
    LoginOut,
    HomeLoginOut,
    setToken,
    setcsrfToken,
    loadingInstance,
    ClearStorage,
    getMenuList
  }
})
