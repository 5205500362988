import request from '@/utils/request'

// 缓存刷新
export function doPurge (data: any) {
  return request({
    url: '/cache/do_purge',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: data
  })
}

// 获取主域名
export function getDomianList (params: any) {
  return request({
    url: '/myweb/domain',
    method: 'GET',
    params: params
  })
}

// 获取子域名
export function getRecord (params: any) {
  return request({
    url: '/myweb/get_record',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params
  })
}

// 获取全部子域名
export function getAllRecord (params: any) {
  return request({
    url: '/myweb/sub_domain_list',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params
  })
}
// 获取操作记录
export function doGetPurgLlist (params: any) {
  return request({
    url: '/cache/purge_list',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params
  })
}

// 域名配置-通用  general,  basic ,iplist
export function getPolicyRule (params: any) {
  return request({
    url: '/strategy/rule',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params

  })
}

// 域名配置-general保存
export function setGeneral (params: any) {
  return request({
    url: '/settings/general',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params

  })
}

// 域名配置-基础安全
export function setBasic (params: any) {
  return request({
    url: '/settings/basic',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params
  })
}

// 域名配置-基础安全-删除
export function setBasicOperate (params: any) {
  return request({
    url: '/settings/operate',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params
  })
}

// 域名配置-黑白名单-添加
export function setIplist (params: any) {
  return request({
    url: '/settings/iplist',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params
  })
}

// 证书
export function getcertList (params: any) {
  return request({
    url: '/cert/get_list',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params
  })
}

// 模板
export function getTplList (params: any) {
  return request({
    url: '/tpl/list',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params
  })
}
// 模板操作
export function tplOperate (params: any) {
  return request({
    url: '/tpl/operate',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params
  })
}

export function tplHostRecord (params: any) {
  return request({
    url: '/tpl/host_record',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params
  })
}

export function tplbindDomains (params: any) {
  return request({
    url: '/tpl/bind_domains',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params
  })
}

export function tplSetup (params: any) {
  return request({
    url: '/tpl/setup',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params
  })
}

export function getcfglist (params: any) {
  return request({
    url: '/strategy/cfg',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params
  })
}

// 证书
// 添加提交表单
export function addSubmitApi (data: any) {
  return request({
    url: '/cert/do_add',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: data
  })
}
// 编辑提交表单
export function certOperate (data: any) {
  return request({
    url: '/cert/operate',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: data
  })
}
// 编辑回填
export function editApi (params: any) {
  return request({
    url: '/cert/info',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params
  })
}
// 删除
export function deleteApi (data: any) {
  return request({
    url: '/ssl/do_del',
    method: 'POST',
    data: data
  })
}
// 强制https
export function changeApi (data: any) {
  return request({
    url: '/cert/do_switch_force',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: data
  })
}
// 强制https
export function certIssuerList (params: any) {
  return request({
    url: 'cert/issuer_list',
    method: 'get',
    params: params
  })
}
