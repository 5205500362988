import i18n from '@/assets/lang'
const { t } = i18n.global

export function timeago (time:string) {
  const data = new Date(time)
  const dateTimeStamp = data.getTime()
  const minute = 1000 * 60 // 把分，时，天，周，半个月，一个月用毫秒表示
  const hour = minute * 60
  const day = hour * 24
  const week = day * 7
  const month = day * 30
  const year = month * 12
  const now = new Date().getTime() // 获取当前时间毫秒
  const diffValue = now - dateTimeStamp// 时间差

  let result = ''
  if (diffValue < 0) {
    result = '' + t('Future')
  }
  const minC = diffValue / minute // 计算时间差的分，时，天，周，月
  const hourC = diffValue / hour
  const dayC = diffValue / day
  const weekC = diffValue / week
  const monthC = diffValue / month
  const yearC = diffValue / year
  if (yearC >= 1) {
    result = Math.floor(yearC) + ' ' + t('年前')
  } else if (monthC >= 1 && monthC < 12) {
    result = Math.floor(monthC) + ' ' + t('月前')
  } else if (weekC >= 1 && weekC < 5 && dayC >= 7 && monthC < 1) {
    result = Math.floor(weekC) + ' ' + t('周前')
  } else if (dayC >= 1 && dayC < 7) {
    result = Math.floor(dayC) + ' ' + t('天前')
  } else if (hourC >= 1 && hourC <= 23) {
    result = Math.floor(hourC) + ' ' + t('小时前')
  } else if (minC >= 1 && minC <= 59) {
    result = Math.floor(minC) + ' ' + t('分钟前')
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = t('现在')
  }

  return result
}

// 获取距离当前多少天的日期 yyyy-mm-dd
export function getBeforeDate (n: number) {
  const date = new Date()
  date.setDate(date.getDate() - n)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const s = year + '-' + (month < 10 ? ('0' + month) : month) + '-' + (day < 10 ? ('0' + day) : day)
  return s
}

// 时间差计算
export function getTimeDiff (time: number) {
  const timeDiff = new Date().getTime() - time

  // 相差天数
  const days = Math.floor(timeDiff / (86400 * 1000))
  // 相差小时数
  const hours = Math.floor(timeDiff % (86400 * 1000) / (3600 * 1000))
  // 相差分钟数
  const minutes = Math.floor(timeDiff % (86400 * 1000) % (3600 * 1000) / (60 * 1000))
  // 相差秒数
  const seconds = Math.floor(timeDiff % (86400 * 1000) % (3600 * 1000) % (60 * 1000) / 1000)

  return {
    date: days > 0 ? days : hours > 0 ? hours : minutes > 0 ? minutes : seconds > 0 ? seconds : '',
    num: days > 0 ? days * 86400 : hours > 0 ? hours * 3600 : minutes > 0 ? minutes * 60 : seconds > 0 ? seconds : 0,
    value: days > 0 ? '/d' : hours > 0 ? '/h' : minutes > 0 ? '/m' : seconds > 0 ? '/s' : '/s',
    text: days > 0 ? '天前' : hours > 0 ? '小时前' : minutes > 0 ? '分钟前' : seconds > 0 ? '秒前' : '现在'
  }
}

// 获取昨天日期
export function getYesterday () {
  const today = new Date().getTime()
  const oneday = 1000 * 60 * 60 * 24
  return startTime(today - oneday)
}

export function weekshow (value: number) {
  const today = new Date().getTime()
  const newdate = new Date()
  return startTime(today - ((newdate.getDay() + value) * 24 * 3600 * 1000))
}

export function monthshow (value: number) {
  const today = new Date().getTime()
  const newdate = new Date()
  return startTime(today - ((newdate.getDate() + value) * 24 * 3600 * 1000))
}

export function startTime (time: string | number | Date) {
  const nowTimeDate = new Date(time)
  // return formatDate(nowTimeDate.setHours(0, 0, 0, 0)/1000)
  return nowTimeDate.setHours(0, 0, 0, 0)
}
export function endTime (time: string | number | Date) {
  const nowTimeDate = new Date(time)
  return nowTimeDate.setHours(23, 59, 59, 999)
}

export function formatDate (now: string) {
  if (!now) {
    return ''
  }
  const time = parseInt(now) * 1000
  const date = new Date(time)
  const Y = date.getFullYear() + '-'
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  const D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' '
  const h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':'
  const m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ':'
  const s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds())
  return Y + M + D + h + m + s
}
