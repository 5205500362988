import Layout from '@/components/Layout/index.vue'
import BlankLayout from '@/components/BlankLayout/index.vue'
import Demo from '@/components/Layout/demo.vue'
import { RouteRecordRaw } from 'vue-router'

const hostValue = window.location.host
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Layout,
    redirect: hostValue === 'zhidun.isurecloud.com' ? '/login' : '/home',
    children: [
      {
        path: '/OrganizationalManagement',
        name: 'OrganizationalManagement',
        component: () => import('@/views/organizationalManagement/index.vue'),
        meta: {
          title: '组织管理',
          activeMenu: '/OrganizationalManagement'
        },
        children: []
      },
      {
        path: '/DomainName',
        name: 'DomainName',
        component: () => import('@/views/domainName/index.vue'),
        meta: {
          title: '域名管理',
          activeMenu: '/DomainName'
        },
        children: []
      },
      {
        path: '/StrategyTemplate',
        name: 'StrategyTemplate',
        component: () => import('@/views/strategyTemplate/index.vue'),
        meta: {
          title: '策略模板',
          activeMenu: '/StrategyTemplate'
        },
        children: [
          
        ]
      },
      {
        path: '/StrategyTemplate/ManageDomain',
        name: 'ManageDomain',
        component: () => import('@/views/strategyTemplate/manageDomain/index.vue'),
        meta: {
          title: '策略模板',
          activeMenu: '/StrategyTemplate'
        },
      },
      {
        path: '/BwList',
        name: 'BwList',
        component: () => import('@/views/bwList/index.vue'),
        meta: {
          title: '黑白名单',
          activeMenu: '/BwList'
        },
        children: []
      },
      {
      path: '/overview',
      component: () => import('@/views/overview/index.vue'),
      name: 'Overview',
      meta: {
        title: '总览',
        activeMenu: '/overview',
        icon: 'icon-pie-chart'
      }
    },

    {
      path: '/domainlist',
      component: () => import('@/views/domain/index.vue'),
      name: 'Domainlist',
      meta: {
        title: '域名列表',
        activeMenu: '/domainlist',
        icon: 'icon-yumingpeizhi'
      }
    },
    {
      path: '/domainlist',
      component: BlankLayout,
      name: 'DomainlistView',
      meta: {
        title: '域名列表',
        noCache: true,
        activeMenu: '/domainlist',
        icon: '',
        keepAlive: false
      },
      children: [{
        path: '/domainlist/record',
        component: () => import('@/views/recordDomain/index.vue'),
        name: 'DomainlistRecord',
        meta: {
          title: '子域列表',
          noCache: true,
          activeMenu: '/domainlist', // 用来选中顶部导航
          parentMenu: '/domainlist', // 用来判断是否需要加返回按钮
          icon: '',
          keepAlive: false
        }
      }]
    },
    {
      path: '/domainconfig',
      component: BlankLayout,
      name: 'domainConfig',
      redirect: '/domainconfig/general',
      meta: {
        title: '域名配置',
        activeMenu: '/domainconfig',
        icon: 'icon-yumingpeizhi'
      },
      children: [
        {
          path: '/domainconfig/general',
          component: () => import('@/views/domainConfig/general/index.vue'),
          name: 'general',
          meta: {
            title: '通用设置',
            activeMenu: '/domainconfig',
            // parentMenu: '/domainconfig',
            icon: ''
          }
        },
        {
          path: '/domainconfig/basicsecurity',
          component: () => import('@/views/domainConfig/basicsecurity/index.vue'),
          name: 'basicsecurity',
          meta: {
            title: '基础安全',
            activeMenu: '/domainconfig',
            // parentMenu: '/domainconfig',
            icon: ''
          }
        },
        {
          path: '/domainconfig/iplist',
          component: () => import('@/views/domainConfig/ipList/index.vue'),
          name: 'ipList',
          meta: {
            title: '黑白名单',
            activeMenu: '/domainconfig',
            // parentMenu: '/domainconfig',
            icon: ''
          }
        },
        {
          path: '/domainconfig/certificate',
          component: () => import('@/views/domainConfig/certificate/index.vue'),
          name: 'Certificate',
          meta: {
            title: '证书管理',
            activeMenu: '/domainconfig',
            icon: 'icon-yumingpeizhi'
          }
        },
        // {
        //   path: '/domainconfig/cache',
        //   component: () => import('@/views/domainConfig/cache/index.vue'),
        //   name: 'cache',
        //   meta: {
        //     title: '缓存刷新',
        //     activeMenu: '/domainconfig',
        //     // parentMenu: '/domainconfig',
        //     icon: ''
        //   }
        // },
        {
          path: '/domainconfig/batchdefense',
          component: () => import('@/views/domainConfig/batchdefense/tableList.vue'),
          name: 'batchdefenseList',
          meta: {
            title: '配置模板',
            activeMenu: '/domainconfig',
            // parentMenu: '/domainconfig',
            icon: ''
          }
        },
        {
          path: '/domainconfig/batchdefense',
          component: BlankLayout,
          name: 'batchdefenseListView',
          meta: {
            title: '配置模板',
            noCache: true,
            activeMenu: '/domainconfig',
            icon: '',
            keepAlive: false
          },
          children: [{
            path: '/domainconfig/batchdefense/manage',
            component: () => import('@/views/domainConfig/batchdefense/index.vue'),
            name: 'batchdefense',
            meta: {
              title: '模板管理',
              noCache: true,
              activeMenu: '/domainconfig', // 用来选中顶部导航
              parentMenu: '/domainconfig/batchdefense', // 用来判断是否需要加返回按钮
              icon: '',
              keepAlive: false
            }
          }]
        }
      ]
    },
    {
      path: '/dashboard',
      component: BlankLayout,
      name: 'Dashboard',
      redirect: '/dashboard/interview',
      meta: {
        title: '统计报表',
        activeMenu: '/dashboard',
        icon: 'icon-yumingpeizhi'
      },
      children: [
        {
          path: '/dashboard/interview',
          component: () => import('@/views/dashboard/interview/index.vue'),
          name: 'DashboardInterview',
          meta: {
            title: '访问统计',
            activeMenu: '/dashboard',
            // parentMenu: '/dashboard',
            icon: ''
          }
        },
        {
          path: '/dashboard/safety',
          component: () => import('@/views/dashboard/safety/index.vue'),
          name: 'DashboardSafety',
          meta: {
            title: '安全统计',
            activeMenu: '/dashboard',
            // parentMenu: '/dashboard',
            icon: ''
          }
        },
        {
          path: '/dashboard/attack',
          component: () => import('@/views/dashboard/attack/index.vue'),
          name: 'DashboardAttack',
          meta: {
            title: '攻击分析',
            activeMenu: '/dashboard',
            // parentMenu: '/dashboard',
            icon: ''
          }
        }
      ]
    },
    {
      path: '/system',
      component: BlankLayout,
      name: 'system',
      redirect: '/system/log',
      meta: {
        title: '系统管理',
        activeMenu: '/system',
        icon: 'icon-setting'
      },
      children: [
        {
          path: '/system/log',
          component: () => import('@/views/system/log/index.vue'),
          name: 'systemLog',
          meta: {
            title: '日志下载',
            activeMenu: '/system',
            // parentMenu: '/system',
            icon: ''
          }
        },
        {
          path: '/system/report',
          component: () => import('@/views/system/report/index.vue'),
          name: 'systemReport',
          meta: {
            title: '报告下载',
            activeMenu: '/system',
            // parentMenu: '/system',
            icon: ''
          }
        },
        {
          path: '/system/alarm',
          component: () => import('@/views/system/alarm/index.vue'),
          name: 'systemAlarm',
          meta: {
            title: '告警设置',
            activeMenu: '/system',
            // parentMenu: '/system',
            icon: ''
          }
        },
        {
          path: '/system/authority',
          component: () => import('@/views/system/authority/index.vue'),
          name: 'systemAuthority',
          meta: {
            title: '权限管理',
            activeMenu: '/system',
            // parentMenu: '/system',
            icon: ''
          }
        }
      ]
    },
    {
      path: '/cache',
      component: () => import('@/views/domainConfig/cache/index.vue'),
      name: 'cache',
      meta: {
        title: '缓存刷新',
        activeMenu: '/cache',
        // parentMenu: '/domainconfig',
        icon: 'icon-huancunshuaxin'
      }
    },
    {
      path: '/message',
      component: () => import('@/views/system/message/index.vue'),
      name: 'Message',
      meta: {
        title: '消息',
        activeMenu: '/message',
        icon: ''
      },
      children: []

    },
    {
      path: '/message',
      component: BlankLayout,
      name: 'MessageView',
      meta: {
        title: '消息',
        noCache: true,
        activeMenu: '/message',
        icon: '',
        keepAlive: false
      },
      children: [{
        path: '/message/detail',
        component: () => import('@/views/system/message/detail.vue'),
        name: 'Messagedetail',
        meta: {
          title: '详情',
          activeMenu: '/message',
          parentMenu: '/message',
          icon: ''
        }
      }]
    },
    {

      path: '/userCenter',
      component: BlankLayout,
      name: 'userCenter',
      redirect: '/userCenter/info',
      meta: {
        title: '个人中心',
        activeMenu: '/userCenter',
        icon: ''
      },
      children: [
        {
          path: '/userCenter/info',
          component: () => import('@/views/userCenter/info/index.vue'),
          name: 'userCenterInfo',
          meta: {
            title: '基础信息',
            activeMenu: '/userCenter',
            icon: ''
          }
        },
        {
          path: '/userCenter/operate',
          component: () => import('@/views/userCenter/operation/index.vue'),
          name: 'Operation',
          meta: {
            title: '操作记录',
            activeMenu: '/userCenter',
            icon: ''
          }
        }
      ]
    }

    ]
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
    children: [],
    meta: {
      activeMenu: '/home'
    }
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    name: 'login',
    meta: {
      title: '登录',
      noCache: true,
      activeMenu: '/login',
      icon: '',
      keepAlive: false
    },
    children: []
  },
  {
    path: '/policy',
    component: () => import('@/views/login/component/policy.vue'),
    name: 'policy',
    meta: {
      title: '服务协议',
      noCache: true,
      activeMenu: '/policy',
      icon: '',
      keepAlive: false
    },
    children: []
  },
  {
    path: '/screen',
    name: 'Screen',
    component: () => import('@/views/screen/index.vue'),
    meta: {
      title: '安全态势感知'
    },
    children: []
  },
  
  {
    path: '/demo',
    component: Demo
  },
  {
    path: '/user/ndlogin/:domain',
    component: () => import('@/components/Layout/ndlogin.vue')
  },
  {
    path: '/404',
    component: () => import('@/components/errorPage/404.vue')
  },
  {
    path: '/:pathMatch(.*)', // 这样的路由配置通常用于实现一种“捕获所有”的行为，当用户访问一个不匹配其他路由的路径是，这个配置就会生效，
    redirect: '/404'
  }
]
export default routes
