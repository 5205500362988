<template>
  <div class="flex j-sb a-c mb-16" >
    <div class="searchSelect w-260" >
      <label for="">{{$t('域名')}}:</label>
      <a-tree-select  class="two_select domianselectFont"  v-model:value="selectDomain" style="width: 100%"
        tree-checkable :height="436"  :tree-data="treeData" show-search  :showArrow="true" :filterTreeNode="filterTreeNode"
        :treeDefaultExpandedKeys="defaultExpanded" :show-checked-strategy="SHOW_PARENT"
        @blur="searchDomain" @change="changeDomainSelect">
        <template #suffixIcon><CaretDownOutlined  class="ant-select-suffix"/></template>
        <template #tagRender="{ label,}">
          <a-tag :closable="false"  >
            {{ label }}
          </a-tag>
        </template>
      </a-tree-select>
    </div>
    <div v-if="route.name != 'DashboardAttack'">
      <a-radio-group v-model:value="dataActive" class="yd-radio" v-if="!isCustom" @change="dataChange">
        <a-radio-button value="0">今天</a-radio-button>
        <a-radio-button value="1">昨天</a-radio-button>
        <a-radio-button value="7">7天</a-radio-button>
        <a-radio-button value="14">14天</a-radio-button>
        <a-radio-button value="30">30天</a-radio-button>
        <a-radio-button value="null" >自定义</a-radio-button>
      </a-radio-group>
      <div class="customDate flex a-c" v-else>
        <div class="back mainClick" @click.stop="isCustom = false"><ArrowLeftOutlined /></div>
        <a-range-picker v-model:value="rangeDate" valueFormat="YYYY-MM-DD" @change="customDataChange"/>
      </div>

    </div>
  </div>
</template>
<script lang="ts" setup>
import { CaretDownOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue'
import { getDomainTreeData } from '@/api/dashboard'
import { onBeforeUnmount, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import dayjs, { Dayjs } from 'dayjs'
import moment from 'moment'
import { getBeforeDate } from '@/utils/formatTime'
import { emitter } from '@/utils/bus'
import { TreeSelect } from 'ant-design-vue'

const VNodes = (_, { attrs }) => {
  return attrs.vnodes
}
const SHOW_PARENT = TreeSelect.SHOW_PARENT
const isChangeDomian = ref(false)
const route = useRoute()
const treeData = ref([])
const dataActive = ref('0')
const rangeDate = ref([])
const isCustom = ref(false)
const defaultExpanded = ref([])
const selectDomain = ref([])
const allSelectDomain = ref([]) // 选择全部域名
const getDomianTree = () => {
  sessionStorage.setItem('domianUrlSelect', '')
  getDomainTreeData({ is_beian: 1, status: 1, record_status: 1 }).then((res: any) => {
    if (res.retcode === 0) {
      if (res.data) {
        let newTreeData = []
        newTreeData = newdomian(res.data)
        newTreeData.forEach(item => {
          item.children = []
          if (item.subdomain && item.subdomain.length > 0) {
            item.subdomain.forEach(si => {
              const newSI = Object.keys(si)
              item.children.push({
                label: si[newSI[0]],
                value: newSI[0]
              })
            })
          }
        })
        treeData.value = [
          {
            label: '全部域名',
            value: '',
            children: newTreeData
          }
        ]
        allSelectDomain.value = []
        newTreeData.forEach(item => {
          if (route.query.did) {
            if (route.query.did === item.value) {
              if (item.children.length > 0) {
                item.children.forEach(ri => {
                  selectDomain.value.push(ri.value)
                })
              }
            }
          } else {
            if (item.children.length > 0) {
              item.children.forEach(ri => {
                selectDomain.value.push(ri.value)
              })
            }
          }

          // if (item.children.length > 0) {
          //   item.children.forEach(ri => {
          //     allSelectDomain.value.push(ri.value)
          //   })
          // }
        })

        defaultExpanded.value = ['']
        emitter.emit('domianUrlSelect', selectDomain.value)
        sessionStorage.setItem('domianUrlSelect', JSON.stringify(selectDomain.value))
      }
    }
  })
}
getDomianTree()

const changeDomainSelect = (value, label, extra) => {
  isChangeDomian.value = true
  if (value.length > 0) {
    if (label.length === 1 && label[0] === '全部域名') {
    } else {
      if (extra.allCheckedNodes.length > 0) {
        const newChecked = []
        extra.allCheckedNodes.forEach(node => {
          if (node.children.length > 0) {
            node.children.forEach(child => {
              newChecked.push(child.node.props.value)
            })
          }
        })
        if (newChecked.length > 0) {
          selectDomain.value = selectDomain.value.concat(newChecked)
        }
      }
    }
  }
  // if (extra.triggerValue) {
  //   if (treeData.value[0].children.length === 1 && treeData.value[0].children[0].children.length === 1) {
  //   } else {
  //     // 如果是全部关闭的时候，默认选择全部域名
  //     if (selectDomain.value.length === 0) {
  //       selectDomain.value = allSelectDomain.value
  //     }
  //   }
  // }
}

const searchDomain = () => {
  if (isChangeDomian.value) {
    // 如果是全部关闭的时候，默认选择全部域名
    if (selectDomain.value.length === 0) {
      selectDomain.value = ['']
    }
    // 判断是不是全选
    if (selectDomain.value.length === 1 && selectDomain.value[0] === '') {
      treeData.value[0].children.forEach(item => {
        // selectDomain.value.push(item.value)// 不需要传父级id、
        if (item.children.length > 0) {
          item.children.forEach(ri => {
            selectDomain.value.push(ri.value)
          })
        }
      })
    } else {
      treeData.value[0].children.forEach(item => {
        if (item.children.length > 0) {
          if (selectDomain.value.indexOf(item.value) > -1) {
            selectDomain.value.splice(selectDomain.value.indexOf(item.value), 1)
          }
        }
      })
    }
    const trimSelectDomain = selectDomain.value.filter(item => item !== '')
    if (trimSelectDomain.length > 0) {
      emitter.emit('domianUrlSelect', Array.from(new Set(trimSelectDomain)))
      sessionStorage.setItem('domianUrlSelect', JSON.stringify(Array.from(new Set(trimSelectDomain))))
    }
  }
}

const date_start = ref('')
const date_end = ref('')
const dataChange = () => {
  if (dataActive.value === 'null') {
    isCustom.value = true
    return
  } else if (dataActive.value === '1') {
    date_end.value = getBeforeDate(Number(dataActive.value))
  } else {
    date_end.value = moment(new Date()).format('YYYY-MM-DD')
  }
  date_start.value = getBeforeDate(Number(dataActive.value))
  emitter.emit('dateSelect', {
    date_end: date_end.value,
    date_start: date_start.value,
    time: dataActive.value
  })
  sessionStorage.setItem('dateSelect', JSON.stringify({
    date_end: date_end.value,
    date_start: date_start.value,
    time: dataActive.value
  }))
}

const customDataChange = () => {
  date_start.value = rangeDate.value[0]
  date_end.value = rangeDate.value[1]
  emitter.emit('dateSelect', {
    date_end: date_end.value,
    date_start: date_start.value
  })
  sessionStorage.setItem('dateSelect', JSON.stringify({
    date_end: date_end.value,
    date_start: date_start.value
  }))
}

const newdomian = (data: { [x: string]: any }) => {
  const dataarr: Array<Object> = []
  const arrdata = Object.keys(data)
  arrdata.forEach(item => {
    const obj = {
      value: item,
      label: data[item].top_domain,
      subdomain: data[item].subdomain
    }
    dataarr.push(obj)
  })
  return dataarr
}

const filterTreeNode = (inputValue: string, treeNode: TreeNode) => {
  if (treeNode.label.indexOf(inputValue) > -1) {
    return treeNode
  }
}

onBeforeUnmount(() => {
  sessionStorage.clear()
})

watch(route, (val) => {
  dataActive.value = '0'
  date_end.value = ''
  date_start.value = ''
  isCustom.value = false
})

</script>
<style lang="less" scoped>
.back{
  width: 50px;
    border: 1px solid var(--colorBorder);
    text-align: center;
    height: 32px;
    line-height: 30px;
    border-right: 0px;
    background: #fff;
}
</style>

<style lang="less">
.ant-select-selection-item[title='全部域名']{
  .ant-select-selection-item-remove{
     display: none;
  }
}
</style>
