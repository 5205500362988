import request from '@/utils/request'

export function getDomainTreeData (params: any) {
  return request({
    url: '/myweb/domain_tree',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {
      data = JSON.stringify(data)
      return data
    }],
    method: 'POST',
    data: params
  })
}

export function mywebDashboard (params: any) {
  return request({
    url: '/myweb/access_stats',
    method: 'GET',
    params: params
  })
}

export function mywebAttack (params: any) {
  return request({
    url: '/myweb/attack_stats',
    method: 'GET',
    params: params
  })
}

export function getAttackData (data: any) {
  return request({
    url: '/attack/event_trend',
    method: 'POST',
    data: data
  })
}

export function getEventList (data: any) {
  return request({
    url: '/attack/event_list',
    method: 'POST',
    data: data
  })
}

export function getAttackTop (data: any) {
  return request({
    url: '/attack/event_top',
    method: 'POST',
    data: data
  })
}

export function getEventStats (data: any) {
  return request({
    url: '/attack/event_stats',
    method: 'POST',
    data: data
  })
}

export function getHomeEventStats (data: any) {
  return request({
    url: '/home/event_stats',
    method: 'POST',
    data: data
  })
}

export function screenAttack (params: any) {
  return request({
    url: '/attack_api',
    method: 'GET',
    params: params
  })
}
export function screenAttackData (params: any) {
  return request({
    url: '/attack_api/get_attack_data',
    method: 'GET',
    params: params
  })
}

export function getSocketToken (params: any) {
  return request({
    url: '/index/socket_token',
    method: 'GET',
    params: params
  })
}

export function getName (params: any) {
  return request({
    url: '/dashboard/get_name',
    method: 'GET',
    params: params
  })
}

export function setName (data: any) {
  return request({
    url: '/dashboard/set_name',
    method: 'POST',
    data: data
  })
}
