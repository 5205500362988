import request from '@/utils/request'
import qs from 'qs'

// 访问控制
export function accessControl(params: any) {
  return request({
    url: '/user/get_access_control',
    method: 'GET',
    params: params
  })
}


// 访问控制修改
export function updateControl(data: any) {
  return request({
    url: '/user/access_control',
    method: 'POST',
    data: data
  })
}

// license
export function licenseInfo(params: any) {
  return request({
    url: '/user/license_info',
    method: 'GET',
    params: params
  })
}
// license修改
export function licenseActivate(data: any) {
  return request({
    url: '/user/activate_license',
    method: 'POST',
    data: data
  })
}

// 用户基本信息
export function Profile(params: any) {
  return request({
    url: '/user/profile',
    method: 'GET',
    params: params
  })
}


// 地址
export function provinceList(params: any) {
  return request({
    url: '/province_city/province',
    method: 'GET',
    params: params
  })
}

// 地址
export function provinceCity(params: any) {
  return request({
    url: '/province_city/city',
    method: 'GET',
    params: params
  })
}

//公司信息修改-手机号邮箱修改
export function userUpdate(data: any) {
  return request({
    url: '/user/update_info',
    method: 'POST',
    data: data
  })
}

//登录保护
export function userMfa(data: any) {
  return request({
    url: '/user/ajax_mfa',
    method: 'POST',
    data: data
  })
}

//发送手机验证码
export function sendSms(data: any) {
  return request({
    url: '/user/send_sms',
    method: 'POST',
    data: data
  })
}
//发送邮箱证码
export function sendEmail(data: any) {
  return request({
    url: '/user/send_email_code',
    method: 'POST',
    data: data
  })
}

//修改密码
export function changePassword(data: any) {
  return request({
    url: '/user/edit_pwd',
    method: 'POST',
    data: data
  })
}


// 消息列表
export function getNotify(params: any) {
  return request({
    url: '/notify/ajax_block',
    method: 'GET',
    params: params
  })
}
// 实时消息
export function loadNotifies(params: any) {
  return request({
    url: '/notify/load_notifies',
    method: 'GET',
    params: params
  })
}
//
export function notifyDo(data: any) {
  return request({
    url: '/notify/ajax_do',
    method: 'POST',
    data: data
  })
}





// 告警接收配置
export function notifyAlarm(params: any) {
  return request({
    url: '/notify/alarm_manager',
    method: 'GET',
    params: params
  })
}
// 告警接收配置
export function notifyChangeAlarm(data: any) {
  return request({
    url: '/notify/alarm_manager',
    method: 'POST',
    data: data
  })
}

// 告警邮箱列表
export function notifyAlarmRules(params: any) {
  return request({
    url: '/notify/alarm_rules',
    method: 'GET',
    params: params
  })
}

// 添加告警邮箱
export function alarmRuleChange(data: any) {
  return request({
    url: '/notify/do_alarm_rules',
    method: 'POST',
    data: data
  })
}

// 添加告警邮箱
export function smtpTestReceive(data: any) {
  return request({
    url: '/notify/test',
    method: 'POST',
    data: data
  })
}

// 邮箱服务器设置
export function smtpDetail(params: any) {
  return request({
    url: '/notify/smtp_config',
    method: 'GET',
    params: params
  })
}


// 退出登录
export function Logout(params: any) {
  return request({
    url: '/user/logout',
    method: 'GET',
    params: params
  })
}


export function exportTask(data: any) {
  return request({
    url: '/report/export_task',
    method: 'POST',
    data: data
  })
}


//确认密码
export function checkPassword(data: any) {
  return request({
    url: '/v2/sub_user/check_password',
    method: 'POST',
    data: data
  })
}
