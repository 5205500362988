<template>
  <a-config-provider :locale="locale"  :autoInsertSpaceInButton="false" :form="{colon:false}" :transformCellText="transformCellText">
    <template #renderEmpty>
      <a-empty :image="require('@/assets/image/empty-image.svg')" />
    </template>
    <router-view/>
  </a-config-provider>
</template>

<script lang="ts" setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
const locale = zhCN

const transformCellText = ({ text, record, index }) => {
  if (!text || text.length === 0) {
    return '(空)'
  }
  return text
}

</script>

<style>
#app {
  height: 100%
}
</style>
