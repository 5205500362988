import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import routes from './router'
import axios from 'axios'
import netconfig from '@/config'
import { getTransitionName } from 'ant-design-vue/lib/_util/transition'
import { adminMenu , userMenu} from '@/hook/menu'

export const asyncRoutes:Array<RouteRecordRaw> = []
const router = createRouter({
  history: createWebHistory('/'),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.title || to.meta.activeMenu === '/home') {
    const hostJson:Record<string, string> = {
      'zhidun.isurecloud.com': '云端智盾',
      'waf.clouddun.com': '力盾',
      'yundun.cycomm.com': '前景云盾',
      'waf.ofidc.com': '云WAF',
      'yundun.wangjumeng.com': '网聚云盾',
      'yundun.wellidc.com': '网聚无限云盾',
      'yidun.ndboxin.com': '鑫盾',
      'dun.pdidc.com': '盾管家'
    }
    const hostname = window.location.hostname.split('.')
    const hostValue = window.location.host
    const customTitle = hostJson[hostValue] ? hostJson[hostValue] : '弈盾'
    let customImg = 'logoIcon'
    localStorage.setItem('customTitle', customTitle)
    document.title = to.meta.activeMenu === '/home' ? customTitle : customTitle + '-' + to.meta.title
    // 自定义logo
    if (hostValue === 'dun.pdidc.com') {
      customImg = hostname[1]
      localStorage.setItem('customImg', customImg)
    } else {
      localStorage.setItem('customImg', '')
    }
  }

  const toName:string|undefined = to.name?.toString()
  if (toName) {
    if (toName.indexOf('login') > -1) {
      const api = axios.create({
        withCredentials: true, // 在跨域请求时，携带cookie
        timeout: 60000,
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
      await api.get(netconfig.baseURL + '/user/info').then((res) => {
        if (res.data.retcode === 0) {
          localStorage.setItem('username', res.data.data.email)
          next('/overview')
        } else {
          next()
        }
      })
    } else {
      const allowPage = ['home', 'policy']
      // 已登录或者公共的静态页面可以直接进入，否则跳到登录页
      if (localStorage.getItem('username')|| allowPage.indexOf(toName) > -1) {
        // if(hasPermission(to.path) || allowPage.indexOf(toName) > -1){
        //   next()
        // }else{
        //   next('/404')
        // }
        next()
      } else {
        next({ name: 'login' })
      }
    }
  } else {
    next()
  }
})
function hasPermission(path:any){
  const isAdmin = localStorage.getItem('isAdmin')
  if(isAdmin === '1'){
    return isKeyInArray(adminMenu,path)
  }else{
    return isKeyInArray(userMenu,path)
  }
}
function isKeyInArray(arr:any, path:string) {
  // 遍历数组中的每个对象
  for (let i = 0; i < arr.length; i++) {
      // 如果当前对象的 key 等于目标 key，则返回 true
      if (arr[i].link === path) {
          return true;
      }
      // 如果当前对象有 children 字段且不为空数组，则递归调用 isKeyInArray 函数检查子节点
      if (arr[i].children && arr[i].children.length > 0) {
          if (isKeyInArray(arr[i].children, path)) {
              return true;
          }
      }
  }
  // 若遍历完数组仍未找到目标 key，则返回 false
  return false;
}
export default router
