import axios, { AxiosRequestConfig } from 'axios'
import { message } from 'ant-design-vue'
import netconfig from '@/config/index'
import { useUserStore } from '@/pinia/modules/user'
import { setCookie } from './cookie'
import router from '@/router'
// import { useRoute } from 'vue-router'
// const route = useRoute()

// 创建一个axios实例
const api = axios.create({
  baseURL: netconfig.baseURL,
  withCredentials: true, // 在跨域请求时，携带cookie
  timeout: 60000
})

// 请求拦截
api.interceptors.request.use(
  async (config: AxiosRequestConfig | any) => {
    // setCookie('wdt', userStore.token, null)
    config.headers = {
      ...config.headers,
      'Content-Type': config.headers['Content-Type'] ? config.headers['Content-Type'] : 'application/x-www-form-urlencoded; charset=UTF-8',
      'X-Requested-With': 'XMLHttpRequest'
      // Cookie: 'YUNYISEC=moqnrcmn5v439l2mk3anffnekb; YIDUN=50ggrf5hmpgrvsn56odprmbrfj'
    }
    return config
  }, error => {
    return Promise.reject(error)
  })

// 响应拦截
const codeArr = [-10003, -10004]
api.interceptors.response.use((res) => {
  const { data: result } = res
  if (res.data.type || res.data.type === 'application/download') { // 下载，返回结果为文件流时
    return result
  } else {
    if (res.data.retcode !== 0 && res.data.retcode !== 1) {
      if(res.data.retcode === -1019){
        router.push('/404')
      }
      if (codeArr.indexOf(res.data.retcode) > -1) {
        localStorage.clear()
        window.location.href = '/login'
      }
      if (res.request.responseURL.indexOf('/user/verify_pwd') > -1) {

      } else {
        message.error(res.data.retmsg)
      }
      return result
    } else {
      return result
    }
  }
}, error => {
  if (error.response.data.retcode !== 0) {
    if (codeArr.indexOf(error.response.data.retcode) > -1) {
      localStorage.clear()
      window.location.href = '/login'
    }
    console.log(error.response.data.retmsg)
    // message.error(error.response.data.message)
    // token失效统一错误处理
    if (error.response.status === 401) {
      // 1. 跳转到登录页
      // router.push('/login')
      // 2. 清空pina和本地存储中的用户数据
      const userStore = useUserStore()
      userStore.LoginOut()
      //  user.clear()
    }
  } else {
    console.log(error.message)
  }
  return error
})

export default api
