import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { store } from '@/pinia'

import Antd from 'ant-design-vue'
import './static/_themes.less'// 动态主题
import './static/styles.less'
import 'uno.css'//样式简写——依赖里

import i18n from '@/assets/lang/index'
import Pagination from '@/components/Pagination/index.vue'

// svg图标
// 导入 svgIcon
import installIcons from '@/icons'

import CountTo from 'vue3-count-to'

// 阿里字体图标
import '@/assets/iconfont/iconfont.js'
import '@/assets/iconfont/iconfont.css'

// 注入全局属性$message
import { message } from 'ant-design-vue'

message.config({
  duration: 2, // 持续时间
  top: '80px', // 到页面顶部距离
  maxCount: 1 // 最大显示数, 超过限制时，最早的消息会被自动关闭
})
const languageTitle = localStorage.getItem('customTitle') ? localStorage.getItem('customTitle') : '弈盾' //根据域名显示不同名字
const app = createApp(App)

app.config.globalProperties.languageTitle = languageTitle
installIcons(app)

app.component('yd-pagination', Pagination)
app.use(store).use(router).use(Antd).use(i18n).use(CountTo).mount('#app')
