<template>
  <div class="headerRight flex a-c">
    <a-space :size="16">
      <a
        class="textC1 mainClick fs-14"
        href="/service/index"
        target="_blank"
        v-if="webtitle === '弈盾'"
        >{{ $t('套餐价格') }}</a
      >
      <div class="message" ref="triggerRef">
        <a-badge id="toggleBtn" :count="badgeCount">
          <i class="iconfont icon-xiaoxi textC2 mainClick" @click.stop="checkMessageClick"></i>
        </a-badge>
        <!----------------------------------- 消息弹窗 ------------------------------>
        <div class="messageList" v-if="showMessageDialog">
          <div class="messageList-title">
            <div class="messageList-title-left">{{ $t('消息中心') }}</div>
            <div class="messageList-title-right">
              <div
                class="mainC mainClick"
                @click.stop="
                  $router.push({ path: '/system/alarm' });
                  showMessageDialog = !showMessageDialog;
                "
              >
                <SettingOutlined class="fs-14" style="margin-right: 4px" />{{ $t('消息配置') }}
              </div>
              <div
                v-if="noticeList.length > 0 && !allReadEd"
                class="mainC mainClick fs-13"
                @click.stop="allRead"
              >
                <i class="iconfont icon-yidu1" style="font-size: 14px !important"></i>
                {{ $t('全部标记已读') }}
              </div>
              <div class="ycl" v-if="allReadEd">
                {{ $t('全部已读') }}
                <CheckCircleOutlined style="color: #00a870" />
              </div>
            </div>
          </div>

          <div style="margin-left: 20px">
            <a-select
              v-model:value="selectValue"
              style="width: 200px"
              :options="selectOptions"
              @change="handleChange"
              :getPopupContainer="(triggerNode:any) => {return triggerNode.parentNode}"
            ></a-select>
          </div>

          <div class="list" v-if="noticeList.length > 0">
            <div
              class="list-item"
              v-for="(item, index) in noticeList"
              :key="item.id"
              @mouseenter="item.hovered = true"
              @mouseleave="item.hovered = false"
            >
              <div class="item-box">
                <div class="item-item">
                  <div class="item-type">{{ findStatusName(item.msg_type) }}</div>
                  <div class="item-time">{{ item.time }}</div>
                </div>
                <div class="item-item">
                  <div class="item-text">
                    {{ item.title }}
                  </div>
                  <div
                    v-show="item.hovered"
                    class="mainC mainClick fs-12"
                    style="width: 70px"
                    @click="read(item.id)"
                  >
                    {{ $t('标记为已读') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a-empty
            v-else
            class="emptyClass mt-24"
            style="margin-top: 20px"
            :image="require('@/assets/image/empty-image.svg')"
          />

          <div class="messageList-bottom">
            <span
              class="mainC mainClick"
              @click.stop="
                $router.push({ path: '/message' });
                showMessageDialog = !showMessageDialog;
              "
              >{{ $t('查看更多') }}
              <RightOutlined />
            </span>
          </div>
        </div>
      </div>
      <!---------------------------------- 消息弹窗  -------------------------------->
      <a
        class="textC2 mainClick fs-16"
        href="/help/index"
        title="帮助中心"
        target="_blank"
        v-if="webtitle === '弈盾'"
      >
        <QuestionCircleFilled />
      </a>
    </a-space>
    <div class="dividerLine"></div>
    <div class="userInfo flex a-c cPointer">
      <svg-icon icon="avatar" class="mr-8"></svg-icon>
      <span class="textC">{{ userName }}</span>
      <CaretDownOutlined class="textC3 ml-12" />
      <div class="userInfoBox">
        <ul class="infoList">
          <li @click.stop="$router.push({ path: '/userCenter/operate' })">
            <i class="iconfont icon-caozuojilu mr-8 fs-14"></i>{{ $t('操作记录') }}
          </li>
          <li @click.stop="$router.push({ path: '/usercenter/info' })">
            <i class="iconfont icon-zhanghaoguanli mr-8 fs-14"></i>{{ $t('基础信息') }}
          </li>
        </ul>
        <ul class="infoList">
          <li @click="logout"><i class="iconfont icon-tuichu mr-8 fs-14"></i>{{ $t('退出') }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, ref, reactive } from 'vue';
  import {
    QuestionCircleFilled,
    RightOutlined,
    SettingOutlined,
    CaretDownOutlined,
    CheckCircleOutlined
  } from '@ant-design/icons-vue';
  import { notifyNum } from '@/api/overview';
  import { useUserStore } from '@/pinia/modules/user';
  import { Logout } from '@/api/user';
  import type { SelectProps } from 'ant-design-vue';
  import { allApi, getListApi } from '@/api/message';

  const userName = ref('');
  const badgeCount = ref(0);

  const webtitle = computed(() => {
    return localStorage.getItem('customTitle') ? localStorage.getItem('customTitle') : '弈盾';
  });
  onMounted(() => {
    userName.value = localStorage.getItem('username');
  });
  const getNoticy = () => {
    notifyNum({}).then((res: any) => {
      if (res.retcode === 0) {
        badgeCount.value = parseInt(res.data.num);
      }
    });
  };
  getNoticy();
  const timer = setInterval(function () {
    getNoticy();
  }, 10000);

  const userStore = useUserStore();
  const logout = () => {
    Logout({}).then((res) => {
      if (res.retcode === 0) {
        userStore.LoginOut();
      }
    });
  };
  onBeforeUnmount(() => {
    clearInterval(timer);
  });

  //TODO
  function checkMessageClick() {
    showMessageDialog.value = !showMessageDialog.value;
    if (showMessageDialog.value) {
      messageList();
    }
  }
  const noticeList = ref<any>([]);
  const showMessageDialog = ref(false);

  const selectValue = ref('');
  const selectOptions = reactive([
    { value: '', label: '全部消息' },
    { value: '1', label: '安全消息' },
    { value: '2', label: '服务消息' },
    { value: '3', label: '故障消息' }
  ]) as any;

  function findStatusName(msg_type: string) {
    if (msg_type) {
      return selectOptions.find((item: any) => item.value === msg_type).label;
    }
  }

  function handleChange(value: string) {
    messageQuery.type = value;
    messageList();
  }
  const messageQuery = reactive({
    curPage: 1,
    perPage: 5,
    type: '',
    status: '-1'
  });
  // 获取消息列表{ label: '全部', value: '' },{ label: '已读', value: '1' },{ label: '未读', value: '-1' }
  const messageList = () => {
    getListApi(messageQuery).then(async (res: any) => {
      if (res?.retcode === 0) {
        noticeList.value = res.data.data;
      }
    });
  };
  // 全部已读
  const allReadEd = ref(false);
  const allRead = () => {
    allApi({ type: 'readall' }).then(async (res: any) => {
      if (res?.retcode === 0) {
        allReadEd.value = true;
        setTimeout(() => {
          messageList();
          allReadEd.value = false;
        }, 3000);
      }
    });
  };
  // 标记已读
  const read = (id: any) => {
    allApi({ type: 'read', id: id }).then(async (res: any) => {
      if (res?.retcode === 0) {
        messageList();
      }
    });
  };

  const triggerRef = ref();
  // 点击页面其他位置隐藏弹出框
  document.addEventListener('click', function (event) {
    if (triggerRef.value && triggerRef.value.contains(event.target)) {
      return false;
    }
    showMessageDialog.value = false;
  });
</script>

<style lang="less" scoped>
  .headerRight {
    height: 64px;

    .dividerLine {
      width: 1px;
      height: 14px;
      background: rgba(0, 0, 0, 0.25);
      margin: 0 24px;
    }

    .userInfo {
      position: relative;
      height: 100%;

      &:hover {
        .userInfoBox {
          display: block;
        }
      }

      .nameBox {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #f3f9fe;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
      }

      .userInfoBox {
        position: absolute;
        right: 6px;
        z-index: 9;
        top: calc(100% - 12px);
        width: 220px;
        border-radius: 4px;
        background: #fff;
        display: none;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
      }

      .infoList {
        padding: 9px 0;

        li {
          height: 40px;
          padding: 0 24px;
          font-weight: 400;
          font-size: 14px;
          line-height: 40px;
          cursor: pointer;

          .anticon {
            color: #9298ac;
          }

          &:hover {
            background: #ecf2fe;
            // color: #FAFAFA;
          }
        }
      }

      .infoList:nth-child(2n) {
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      }
    }
  }

  .message {
    position: relative;
  }

  .messageList {
    z-index: 9;
    position: absolute;
    width: 480px;
    height: v-bind("noticeList.length > 0 ? '450px' : '340px'");
    top: 64px;
    right: -130px;
    background-color: #fff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);

    .messageList-title {
      display: flex;
      height: 54px;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      background: var(--Color-, #fff);

      .messageList-title-left {
        font-size: 14px;
        font-weight: 600;
        width: 50%;
        height: 100%;
        line-height: 54px;
        padding-left: 20px;
      }

      .messageList-title-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 200px;
        padding-right: 20px;
        line-height: 54px;
        gap: 12px;
      }
    }

    .messageList-bottom {
      height: 54px;
      border-top: 1px solid #eee;
      position: absolute;
      bottom: 0;
      width: 100%;
      line-height: 54px;
      text-align: center;
    }

    .list {
      height: 250px;
    }
  }

  .list-item {
    width: 100%;
    padding: 4px 20px;
    // background-color: aquamarine;
    // border: 1px solid #000;

    .item-box {
      width: 100%;
      height: 48px;
      display: flex;
      padding-left: 12px;
      flex-direction: column;
      justify-content: center;
      border-left: 2px solid #3860f4;

      .item-item {
        height: 24px;
        display: flex;
        justify-content: space-between;
        font-family: 'PingFang SC';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .item-type {
      width: 68px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border: 1px solid #dee5ff;
      background: var(--Color-, #f8f8ff);
      color: var(--Color-, #3860f4);
      font-variant-numeric: lining-nums tabular-nums;
    }

    .item-time {
      width: 50%;
      text-align: right;
      color: rgba(0, 0, 0, 0.3);
    }

    .item-text {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      color: var(--Color-1, #0a1633);
      font-weight: 500;
      font-variant-numeric: lining-nums tabular-nums;
      text-overflow: ellipsis;
    }
  }

  .list-item:hover {
    background-color: #f8f8ff;
    cursor: pointer;
  }
</style>
