<template>
  <a-menu
    theme="dark"
    v-model:selectedKeys="selectedKeys"
    mode="horizontal"
    style="width: calc(100% - 220px)"
  >
    <template v-for="item in navdata" :key="item.path">
      <a-menu-item>
        <template #icon>
          <i :class="['iconfont', item.meta?.icon]"></i>
        </template>
        <router-link class="header_menu_link" :to="{ path: item.path }">
          {{ item.meta?.title }}
        </router-link>
      </a-menu-item>
    </template>
  </a-menu>
</template>
<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { adminMenu, userMenu } from "@/hook/menu";
import { formatMenus } from "./helper";
import { useRoute } from "vue-router";

const route = useRoute();
const navdata = computed(function () {
  const mockMenu =
    localStorage.getItem("isAdmin") === "1" ? adminMenu : userMenu;
  return formatMenus(mockMenu);
});

watch(route, (val) => {
  selectedKeys.value = [val.meta.activeMenu];
});

const activeMenu = computed(() => {
  const { meta, path } = route;
  if (meta !== null || meta !== undefined) {
    if (meta.activeMenu) {
      return meta.activeMenu;
    }
  }
});

const selectedKeys = ref<unknown[]>([activeMenu.value]);
</script>

<style lang="less" scoped></style>
