<template>
  <ul>
    <li v-for="(item, index) in menus" :key="item.path"  :class="{ 'active':item.path.startsWith(route.meta.parentMenu ? route.meta.parentMenu :  route.path)}" >
      <router-link :to="{path:String(item.path),query:recordDomian? {recordDomian:recordDomian} : {}}" :target="item.path === '/screen' ? '_blank' : ''">
        <i class="iconfont icon-zhanweitubiao"></i>
        <span class="menuTitle">{{ item.meta?.title }}</span>
      </router-link>
    </li>
  </ul>
</template>
<script lang='ts' setup>
import router from '@/router'
import { defineProps, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
const props = defineProps({
  list: {
    type: Array,
    default: () => []
  },
  activePath: String
})
const recordDomian = ref('')

const route = useRoute()

const recordSelectName = ['basicsecurity', 'ipList', 'general', 'certificate', 'cache', 'batchdefenseList']
const menus = ref<any[]>([])
watch(() => props.list, () => {
  if (recordSelectName.indexOf(route.name) > -1) {
    recordDomian.value = route.query.recordDomian ? route.query.recordDomian : ''
  } else {
    recordDomian.value = ''
  }
  menus.value = props.list.map((row) => {
    return {
      ...row
    }
  })
}, {
  deep: true,
  immediate: true
})
</script>

<style lang="less" scoped>
ul{
  padding:0 12px;
  li{
    a{
      display:block;
      padding: 9px 12px;
      border-radius: 4px;
      color:var(--color-3);
      font-size: 14px;
      line-height: 22px;
    }
    .iconfont{
      margin-right:8px;
      vertical-align: bottom;
    }
    &:hover{
      a{
        background:#F8F8FF;
      }
    }
    &.active{
      a{
        background:var(--colorPrimary);
        color:#FFF;
      }
      &:hover{
        a{
          background:var(--colorPrimary);
        }
      }
    }

  }
}
</style>
