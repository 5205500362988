<template>
  <!-- <div class="headerRight flex a-c"> -->
  <a-tooltip id="adminTooltip"
    ><template #title>退出</template>
    <div class="flex a-c cPointer backContainer" @click="logout">
      <svg-icon icon="avatar" class="mr-8"></svg-icon>
      <span class="textC">{{ userName }}</span>
      <i class="iconfont icon-tuichu exit"></i>
    </div>
  </a-tooltip>
  <!-- </div> -->
</template>
<script setup lang="ts">
import { Logout } from "@/api/user";
import { useUserStore } from "@/pinia/modules/user";
import { onMounted, ref } from "vue";

const userName = ref("");
onMounted(() => {
  userName.value = localStorage.getItem("username");
});

const userStore = useUserStore();
const logout = () => {
  Logout({}).then((res) => {
    if (res.retcode === 0) {
      userStore.LoginOut();
    }
  });
};
</script>

<style lang="less" scoped>
// .headerRight {
//   height: 64px;

//   .dividerLine {
//     width: 1px;
//     height: 14px;
//     background: rgba(0, 0, 0, 0.25);
//     margin: 0 24px;
//   }
// }
.backContainer {
  position: relative;
  padding: 0 8px;
  height: calc(100% - 20px);
  border-radius: 4px;
}
.backContainer:hover {
  background-color: #f8f8ff;
  .exit {
    color: #3860f4;
  }
}
.exit {
  margin-left: 12px;
  color: #9d9d9d;
  &:hover {
    color: #3860f4;
  }
}
</style>
