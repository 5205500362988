/*
  系统一二级菜单
*/
export const adminMenu = [
  {
    id: 6,
    name: '组织管理',
    pid: 0,
    icon: 'icon-setting',
    sort: 1,
    link: '/OrganizationalManagement',
    key: 'OrganizationalManagement',
    children: []
  },
  {
    id: 7,
    name: '域名管理',
    pid: 0,
    icon: 'icon-yumingpeizhi',
    sort: 1,
    link: '/DomainName',
    key: 'DomainName',
    children: []
  },
  {
    id: 8,
    name: '策略模板',
    pid: 0,
    icon: 'icon-gongnengguanli',
    sort: 1,
    link: '/StrategyTemplate',
    key: 'StrategyTemplate',
    children: [
    ]
  },
  {
    id: 9,
    name: '黑白名单',
    pid: 0,
    icon: 'icon-setting',
    sort: 1,
    link: '/BwList',
    key: 'BwList',
    children: []
  },
]

export const userMenu = [
  {
    id: 1,
    name: '总览',
    pid: 0,
    icon: 'icon-pie-chart',
    sort: 1,
    link: '/overview',
    key: 'Overview',
    children: []
  },
  {
    id: 2,
    name: '域名列表',
    pid: 0,
    icon: 'icon-yumingpeizhi',
    sort: 1,
    link: '/domainlist',
    key: 'Domainlist',
    children: []
  },
  {
    id: 3,
    name: '域名配置',
    pid: 0,
    icon: 'icon-gongnengguanli',
    sort: 1,
    link: '/domainconfig',
    key: 'domainConfig',
    children: [{
      id: 31,
      name: '通用设置',
      pid: 3,
      icon: '',
      sort: 1,
      link: '/domainconfig/general',
      key: 'general',
      children: []
    },
    {
      id: 32,
      name: '基础安全',
      pid: 3,
      icon: '',
      sort: 1,
      link: '/domainconfig/basicsecurity',
      key: 'basicsecurity',
      children: []
    },
    {
      id: 33,
      name: '黑白名单',
      pid: 3,
      icon: '',
      sort: 1,
      link: '/domainconfig/iplist',
      key: 'ipList',
      children: []
    },
    {
      id: 34,
      name: '证书管理',
      pid: 3,
      icon: '',
      sort: 1,
      link: '/domainconfig/certificate',
      key: 'Certificate',
      children: []
    },
    // {
    //   id: 35,
    //   name: '缓存刷新',
    //   pid: 3,
    //   icon: '',
    //   sort: 1,
    //   link: '/domainconfig/cache',
    //   key: 'cacheRrefresh',
    //   children: []
    // },
    {
      id: 36,
      name: '配置模板',
      pid: 3,
      icon: '',
      sort: 1,
      link: '/domainconfig/batchdefense',
      key: 'batchdefenseList',
      children: []
    }]
  },
  {
    id: 6,
    name: '缓存刷新',
    pid: 0,
    icon: 'icon-huancunshuaxin',
    sort: 1,
    link: '/cache',
    key: 'cache',
    children: []
  },
  {
    id: 4,
    name: '统计报表',
    pid: 0,
    icon: 'icon-signal',
    sort: 1,
    link: '/dashboard',
    key: 'Dashboard',
    children: [{
      id: 41,
      name: '访问统计',
      pid: 4,
      icon: '',
      sort: 1,
      link: '/dashboard/interview',
      key: 'DashboardInterview',
      children: []
    }, {
      id: 42,
      name: '安全统计',
      pid: 4,
      icon: '',
      sort: 1,
      link: '/dashboard/safety',
      key: 'DashboardSafety',
      children: []
    }, {
      id: 43,
      name: '攻击分析',
      pid: 4,
      icon: '',
      sort: 1,
      link: '/dashboard/attack',
      key: 'DashboardAttack',
      children: []
    }, {
      id: 44,
      name: '态势感知',
      pid: 4,
      icon: '',
      sort: 1,
      link: '/screen',
      key: 'Screen',
      children: []
    }]
  },
  {
    id: 5,
    name: '系统管理',
    pid: 0,
    icon: 'icon-setting',
    sort: 1,
    link: '/system',
    key: 'System',
    children: [{
      id: 51,
      name: '日志下载',
      pid: 5,
      icon: '',
      sort: 1,
      link: '/system/log',
      key: 'systemLog',
      children: []
    },
    {
      id: 52,
      name: '报告下载',
      pid: 5,
      icon: '',
      sort: 1,
      link: '/system/report',
      key: 'systemReport',
      children: []
    },
    {
      id: 53,
      name: '告警设置',
      pid: 5,
      icon: '',
      sort: 1,
      link: '/system/alarm',
      key: 'systemAlarm',
      children: []
    }, {
      id: 54,
      name: '权限管理',
      pid: 5,
      icon: '',
      sort: 1,
      link: '/system/authority',
      key: 'systemAuthority',
      children: []
    }]
  }
]

export const mockMenu = localStorage.getItem('isAdmin')==='1' ? adminMenu : userMenu
