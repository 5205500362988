import { createI18n } from 'vue-i18n'

// ant-design 中的语言配置
import antZhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

// 自己的语言配置
import enLocale from './en'
import zhLocale from './zh'

// 语言配置整合
const messages = {
  en: {
    ...enLocale
  },
  'zh-cn': {
    ...zhLocale,
    antZhCN
  }
}
const locale = 'zh-cn'
// 创建 i18n
const i18n = createI18n({
  legacy: false,
  globalInjection: true, // 全局模式，可以直接使用 $t
  allowComposition: true,
  locale: locale,
  messages: messages
})
dayjs.locale(locale)
export default i18n
